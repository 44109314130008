<template>
  <div class="redirect__to d-flex align-center">
    <router-link class="text-decoration-none" :to="route ? { name: route } : routeWithId">
      <v-icon small class="mr-3">fas fa-arrow-left</v-icon>
      {{ title }}
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    // Title of the route
    title: {
      type: String,
      default: '',
    },
    // Name of route
    route: {
      type: String,
      default: null,
    },
    routeWithId: {
      type: Object,
      default: () => {
        return {
          name: null,
          params: {
            id: '',
          },
        };
      },
    },
  },
};
</script>
<style lang="scss">
.redirect__to {
  a {
    background: #dddd;
    border-radius: 7px;
    color: #6e7a81;
    padding: 5px 15px;
  }
  a:hover {
    // background-color: #ffffff;
  }
}
</style>
