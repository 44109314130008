<template>
  <div class="form-component">
    <label v-if="showLabel" class="d-flex" :style="'font-size:' + size">{{ label }}</label>
    <template v-if="typeComponent === 'metaDes'">
      <v-textarea
        :height="height"
        :placeholder="placeholder"
        :suffix="suffix"
        :prefix="prefix"
        :type="type"
        v-if="model.length === 2"
        :error-messages="errors"
        @blur="checkValidateWithBlurInput"
        @input="checkValidateWithBlurInput"
        :hide-details="!isValidate"
        v-model="$parent[model[0]][model[1]]"
        autocomplete="off"
      ></v-textarea>
    </template>
    <template v-if="typeComponent === 'input'">
      <v-text-field
        :placeholder="placeholder"
        :suffix="suffix"
        :prefix="prefix"
        :type="type"
        v-if="model.length === 2"
        :error-messages="errors"
        @blur="checkValidateWithBlurInput"
        @input="checkValidateWithBlurInput"
        :hide-details="!isValidate"
        v-model="$parent[model[0]][model[1]]"
        autocomplete="off"
      ></v-text-field>

      <v-text-field
        :placeholder="placeholder"
        :prefix="prefix"
        :suffix="suffix"
        :type="type"
        :hide-details="!isValidate"
        v-if="model.length === 1"
        :error-messages="errors"
        @blur="checkValidateWithBlurInput"
        v-model="$parent[model[0]]"
        @input="checkValidateWithBlurInput"
        autocomplete="off"
      ></v-text-field>
    </template>
    <template v-if="typeComponent === 'area'">
      <v-textarea
        :type="type"
        v-if="model.length === 2"
        :hide-details="!isValidate"
        :error-messages="errors"
        @blur="checkValidateWithBlurInput"
        @input="checkValidateWithBlurInput"
        v-model="$parent[model[0]][model[1]]"
        autocomplete="off"
      ></v-textarea>

      <v-textarea
        :type="type"
        v-if="model.length === 1"
        :error-messages="errors"
        :hide-details="!isValidate"
        @blur="checkValidateWithBlurInput"
        v-model="$parent[model[0]]"
        @input="checkValidateWithBlurInput"
        autocomplete="off"
      ></v-textarea>
    </template>
    <template v-if="typeComponent === 'select'">
      <v-select
        :item-text="itemText"
        :hide-details="!isValidate"
        :item-value="itemValue"
        v-if="model.length === 1"
        :error-messages="errors"
        @blur="$parent.checkValidateWithBlurInput"
        v-model="$parent.$parent[model[0]]"
        @input="$parent.checkValidateWithBlurInput"
        autocomplete="off"
        :clearable="clearable"
        :items="itemsSelect"
      ></v-select>
      <v-select
        :item-text="itemText"
        :item-value="itemValue"
        :hide-details="!isValidate"
        v-if="model.length === 2"
        :error-messages="errors"
        @blur="checkValidateWithBlurInput"
        @input="checkValidateWithBlurInput"
        v-model="$parent[model[0]][model[1]]"
        :clearable="clearable"
        :items="itemsSelect"
      ></v-select>
    </template>
  </div>
</template>

<script>
export default {
  // With type Parent - InputDefault
  props: {
    height: {
      type: Number,
      default: 34,
    },
    // with v-select
    showLabel: {
      type: Boolean,
      default: true,
    },
    suffix: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      default: 'name',
    },
    size: {
      type: String,
      default: '14px',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    itemsSelect: {
      type: Array,
      default: () => [{ name: 1, id: 1 }],
    },

    // type : input , area, select
    typeComponent: {
      type: String,
      default: 'input',
    },
    type: {
      type: String,
      default: 'text',
    },
    // nest object v-model="a.b" in Parent ['a','b']
    model: {
      type: Array,
      default: () => [''],
    },
    //list default validate
    isValidate: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Array,
      default: () => [
        { type: 'phone' },
        { type: 'email' },
        { type: 'required' },
        { type: 'minLength', min: 0 },
        { type: 'maxLength', max: 255 },
        { type: 'alpha' },
        { type: 'domain' },
      ],
    },
    min: {
      type: Number,
      default: 0,
    },
    //with validate maxlength
    max: {
      type: Number,
      default: 255,
    },
    label: {
      type: String,
      default: 'label',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modelValidate: null,
    };
  },
  // created() {

  // },
  methods: {
    checkValidateWithBlurInput() {
      if (this.isValidate) {
        if (this.model.length === 2) {
          this.$parent.$v[this.model[0]][this.model[1]].$touch();
        } else {
          this.$parent.$v[this.model[0]].$touch();
        }
      } else {
        return;
      }
    },
  },
  computed: {
    errors() {
      const errors = [];
      if (this.isValidate) {
        if (this.model.length === 1) {
          if (this.$parent.$v[this.model[0]] !== undefined && !this.$parent.$v[this.model[0]].$dirty) return errors;
          for (let i = 0; i < this.validate.length; i++) {
            if (
              this.$parent.$v[this.model[0]][this.validate[i].type] !== undefined &&
              !this.$parent.$v[this.model[0]][this.validate[i].type]
            ) {
              if (this.validate[i].type === 'minLength' || this.validate[i].type === 'maxLength') {
                errors.push(
                  this.$t(`validate.${this.validate[i].type}`, {
                    type: this.validate[i].type,
                    number: this.validate[i].type === 'minLength' ? this.min : this.max,
                  }),
                );
              } else {
                errors.push(
                  this.$t(`validate.${this.validate[i].type}`, {
                    field: this.fieldName ? this.fieldName : this.model[0],
                  }),
                );
              }
            }
          }
          return errors;
        }
        if (this.model.length === 2) {
          if (
            this.$parent.$v[this.model[0]][this.model[1]] !== undefined &&
            !this.$parent.$v[this.model[0]][this.model[1]].$dirty
          )
            return errors;
          for (let i = 0; i < this.validate.length; i++) {
            if (
              this.$parent.$v[this.model[0]][this.model[1]][this.validate[i].type] !== undefined &&
              !this.$parent.$v[this.model[0]][this.model[1]][this.validate[i].type]
            ) {
              if (this.validate[i].type === 'minLength' || this.validate[i].type === 'maxLength') {
                errors.push(
                  this.$t(`validate.${this.validate[i].type}`, {
                    type: this.validate[i].type,
                    number: this.validate[i].type === 'minLength' ? this.min : this.max,
                  }),
                );
              } else {
                errors.push(
                  this.$t(`validate.${this.validate[i].type}`, {
                    field: this.fieldName ? this.fieldName : this.model[0][1],
                  }),
                );
              }
            }
          }
          return errors;
        }
        return errors;
      }
      return errors;
    },
  },
};
</script>
