import { AnalyticService } from '../../apis/analytics';

const state = {
  trafficSources: [],
  total: 0,
  options: {
    total: 500,
  },
};

const getters = {
  getTrafficSources: state => state.trafficSources,
  getTotalTrafficSources: state => state.total,
};
const actions = {
  trafficSourceHistogram({ commit }, args = {}) {
    console.log('=====>', args);
    return new Promise((resolve, reject) => {
      let strStores = Array.isArray(args.stores) ? args?.stores.join(',') : args.stores;
      AnalyticService.fetchTrafficSource(args.fromTime, args.toTime, strStores, args?.timezone)
        .then(({ data }) => {
          commit('SET_TRAFFIC_SOURCE', data);
          return resolve({ message: 'success' });
        })
        .catch(ex => {
          return reject({ message: ex.message });
        });
    });
  },
};
const mutations = {
  SET_TRAFFIC_SOURCE(state, data) {
    let total = 0;
    data.map(item => {
      total += item.session_count.value;
    });
    state.total = total; //data.reduce((a, b) => a + b.session_count.value);
    state.trafficSources = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
