let path = `/users/`;
import accountRequest from './request/accountRequest';
function update(user) {
  return accountRequest.put(path, user);
}

function get() {
  accountRequest.setStoreId('');
  return accountRequest.get(path + `info`);
}

function getStores() {
  accountRequest.setStoreId('');
  return accountRequest.get(path + `stores`);
}

function addStore(store) {
  return accountRequest.post(path + `create-store`, store);
}

export const userService = {
  update,
  get,
  getStores,
  addStore,
};
