export default [
  // Main Page
  {
    path: 'user-profile',
    name: 'website.profile',
    component: () => import('@/views/website/profile'),
  },
  {
    path: 'test',
    name: 'website.test',
    component: () => import('@/views/website/test/DAT'),
  },
];
