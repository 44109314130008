export default [
  {
    path: 'topUp',
    name: 'website.topUp',
    component: () => import('@/views/website/topUp'),
  },
  {
    path: 'topUp/history',
    name: 'website.topUp.history',
    component: () => import('@/views/website/topUp/history'),
  },
];
