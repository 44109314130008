export default [
  {
    path: 'menufilter',
    name: 'website.menufilter',
    component: () => import('@/views/website/menufilter/index'),
  },
  {
    path: 'menufilter/create',
    name: 'website.menufilter.create',
    component: () => import('@/views/website/menufilter/Create'),
  },
  {
    path: 'menufilter/update/:id',
    name: 'website.menufilter.update',
    component: () => import('@/views/website/menufilter/Update'),
  },
];
