const state = {
  mini: false,
  //error success warning
};
const getters = {
  mini: state => state.mini,
};
const mutations = {
  setNavigationMini(state, data) {
    state.mini = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
