import { storeService } from '../../apis/store.s';
import { FETCH_STORES_DONE } from '../mutations.type';

import { FETCH_STORES } from '../actions.type';
const initialState = {
  stores: [],
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_STORES](context) {
    const { data } = await storeService.list();

    context.commit(FETCH_STORES_DONE, data);
    return data;
  },
};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  [FETCH_STORES_DONE](state, data) {
    state.stores = data?.filter(store => {
      if (typeof store == 'object') {
        return store;
      }
    });
    console.log('debug:', state.stores);
  },
};

const getters = {
  stores(state) {
    return state.stores;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
