export default [
  // Main Page
  {
    path: 'member',
    name: 'website.member',
    component: () => import('@/views/website/member'),
  },
  {
    path: 'member/:id',
    name: 'website.member.update',
    component: () => import('@/views/website/member/Detail'),
  },
];
