import Vue from 'vue';

Vue.filter('capitalize', function(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('colorSwitcher', function(value) {
  console.log('============>', value);
  switch (value) {
    case 'paid':
      return 'success';
    case 'fulfilled' || 'paid':
      return 'success';
    case 'unfulfilled':
      return 'warning';
    default:
      return 'secondary';
  }
});
