import $ from 'jquery';
import _ from 'lodash';
import store from '../store';
import Vuex from 'vuex';
class Utilities {
  scrollToTop(y) {
    $('html, body').animate({ scrollTop: y || 0 }, 'slow');
  }

  getNestedAttribute(object, dottedString) {
    let tmpObject = object;
    let nestedAttributes = dottedString.split('.');
    let getter = (obj, key) => {
      if (!obj || typeof obj !== 'object' || typeof obj[key] === 'undefined') {
        return (tmpObject = null);
      }

      return (tmpObject = obj[key]);
    };

    nestedAttributes.forEach(key => {
      tmpObject = getter(tmpObject, key);
    });

    return tmpObject;
  }
  checkRouter(route) {
    let path = route.fullPath;
    if (path === `/admin/` || path === `/admin`) return `homes`;
    if (path.includes(`/admin/orders`) || path.includes(`/admin/abandoned`)) return `orders`;
    // if (path.includes(`/admin/orders/detail`)) return `wirte_orders`;
    if (
      path.includes(`/admin/products`) ||
      path.includes(`/admin/collections`) ||
      path.includes(`/admin/inventory`) ||
      path.includes(`/admin/product-feeds`) ||
      path.includes(`/admin/product-personalized`) ||
      path.includes(`/admin/bulkupdates`)
    )
      return `products`;
    if (path.includes(`/admin/analytics`)) return `dashboards`;
    if (path.includes(`/admin/sales-reports`)) return `sale_reports`;
    if (path.includes(`/admin/discount`)) return `discounts`;
    if (path.includes(`/admin/customer`)) return `customers`;
    if (path.includes(`/admin/settings`)) return `settings`;
    if (path.includes(`/admin/settings/payments`)) return `payment_providers`;

    if (path.includes(`/admin/list-app`) || path.includes(`/admin/app`)) return `apps`;
    if (path.includes(`admin/themes`)) return `themes`;
    if (path.includes(`admin/pages`)) return `pages`;
    if (path.includes(`admin/menus`)) return `navigations`;
    if (path.includes(`admin/preferences`)) return `preferences`;
    if (path.includes(`admin/domain`)) return `domains`;
    else return `store`;
  }
  checkPermisson(nameRole, user) {
    if (user) {
      let roleList = [];
      roleList = user.scopes.map(it => it);
      roleList.push(`store`);
      roleList.push(`report`);
      if (user.isStoreOwner || user.isStoreAdmin) return true;
      return roleList.includes(nameRole) ? true : false;
    }
    return false;
    // return true;
  }
  comparisonTwoObject(object1, object2) {
    return _.isEqual(object1, object2);
  }

  /**
   * Merge 2 objects
   *
   * @param object
   * @param object
   * @param function
   * @return object
   */
  mergeObjects(object1, object2, customizer) {
    if (typeof customizer === 'function') {
      return _.mergeWith(object1, object2, customizer);
    }

    return _.merge(object1, object2);
  }

  /**
   * String limit
   *
   * @param string
   * @param integer Limit
   * @return string
   */
  stringLimit(str, limit) {
    if (!str) {
      return '';
    }

    if (str.length <= limit) {
      return str;
    }

    return str.substring(0, limit) + '...';
  }

  changeChipName(str) {
    return (
      str.slice(0, 1).toUpperCase() +
      str.slice(1, str.search('_')) +
      ' ' +
      str.charAt(str.search('_') + 1).toUpperCase() +
      str.slice(str.search('_') + 2)
    );
  }

  // changeChipNameOrder(str) {
  //   let a;
  //   if (str.slice(str.length - 2, 2).parseInt() == isNaN) {
  //     a = str.slice(str.length - 2, 2).parseInt();
  //     console.log(1);
  //   } else {
  //     a = str.slice(str.length - 2, 1).parseInt();
  //   }
  //   return 'Order from ' + str.slice(str.search('m'), 2).parseInt() + ' to ';
  // }

  ucfirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  /**
   * Handle error response exception
   *
   * @param object Exception
   * @param object Errors object
   */
  handleErrorResponse(e) {
    let errors = [];

    // If known error
    let responseErrors = this.getNestedAttribute(e, 'response.data.errors');
    if (responseErrors) {
      // Map to local errors
      errors = responseErrors.map(error => {
        return error.title;
      });
    } else {
      errors = ['Oops! Unknown error!'];
    }

    return errors;
  }
  formatDate(e) {
    console.log(e[0]);
    // let startDate = e[0].slice(4, 7) + e[0].slice(8, 10) + e[0].slice(9, 14);
    // let endDate = e[1].slice(4, 7) + e[1].slice(8, 10) + e[1].slice(9, 14);
    // let array = [startDate, endDate];
    // return array;
    return true;
  }
}

export default new Utilities();
