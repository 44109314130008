const state = {
  pageLayout: null,
  book: null,
  page: null,
  characters: [],
  blayout: null,
  slot: null,
  mode: 'select',
  selected: [],
};

const mutations = {
  'bookeditor/open': (state, data) => {
    state.book = data.book;
    state.page = data.page;
    state.pageLayout = data.pageLayout;
    state.blayout = data.blayout;
    state.slot = null;
    state.characters = data.characters || [];
    state.selected = [];
  },
  'bookeditor/close': state => {
    state.book = null;
    state.page = null;
    state.pageLayout = null;
    state.blayout = null;
    state.slot = null;
    state.selected = [];
    state.characters = [];
  },
  'bookeditor/loadCharacters': (state, characters) => {
    state.characters = characters || [];
  },
  'bookeditor/setMode': (state, mode) => {
    state.mode = mode;
  },
  'bookeditor/setStyle': (state, style) => {
    if (!state.blayout) return;
    state.blayout.style = {
      ...state.page.style,
      ...style,
    };
  },
  'bookeditor/select': (state, id) => {
    state.selected = Array.isArray(id) ? id : [id];
  },
  'bookeditor/clearSelection': state => {
    if (state.selected.length > 0) {
      state.selected = [];
    }
  },
  'bookeditor/removeObject': (state, id) => {
    if (!state.blayout || !state.blayout.objects || state.blayout.objects.length == 0) return;
    let objects = [];
    let selected = [];
    for (let obj of state.blayout.objects) {
      if (Array.isArray(id) && id.indexOf(obj.id) >= 0) continue;
      if (obj.id == id) continue;
      if (state.selected.indexOf(obj.id) >= 0) selected.push(obj.id);
      objects.push(obj);
    }
    state.blayout.objects = objects;
    state.selected = selected;
  },
  'bookeditor/visible': (state, id) => {
    if (!state.blayout || !state.blayout.objects) return;
    for (let obj of state.blayout.objects) {
      if (obj.id === id) obj.visible = !obj.visible;
    }
  },
  'bookeditor/swapObjects': (state, data) => {
    if (!state.blayout || !state.blayout.objects) return;
    if (!data || !data.src || !data.dest || data.src == data.dest) return;
    let { objects } = state.blayout;
    let src = -1;
    let dest = -1;
    for (let i in objects) {
      let obj = objects[i];
      if (obj.id === data.src) src = parseInt(i);
      if (obj.id === data.dest) dest = parseInt(i);
    }
    if (src < 0 || dest < 0) return;
    let d = src < dest ? 1 : -1;
    let tmp = objects[src];
    for (let i = src; i != dest; i += d) {
      objects[i] = objects[i + d];
    }
    objects[dest] = tmp;
    for (let i in objects) {
      objects[i].ordinal = parseInt(i) + 1;
    }
    state.blayout.objects = [...objects];
  },
  'bookeditor/presert': (state, obj) => {
    state.slot = obj;
  },
  'bookeditor/addObject': (state, obj) => {
    if (!state.blayout || !obj || !obj.id) return;
    state.blayout.objects.push(obj);
  },
};

export default {
  state,
  mutations,
};
