import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let path = '/media/presigned-url-upload';

function get(file) {
  return storeRequest.get(path + '?filename=' + file.filename);
}

function upload(url, file) {
  return storeRequest.put(url, file);
}

export const uploadService = {
  get,
  upload,
};
