<template>
  <div class="file-upload-container">
    <div
      @click="clickUploadFile"
      :class="multiple ? 'upload-dragger' : 'upload-one-image'"
      v-if="(!image || !image.src || image.src == '') && !editable"
    >
      <v-col class="px-3 py-3" cols="12">
        <div class="file-drop-area">
          <div class="text-center">
            <div class="fake-btn"><v-icon large>mdi-publish</v-icon></div>
            <div class="file-msg">upload files here</div>
          </div>
        </div>
      </v-col>
    </div>
    <template v-else>
      <v-hover>
        <template class="px-3" v-slot:default="{ hover }">
          <v-card relative :max-width="maxWidth">
            <!-- Image -->
            <v-img
              contain
              :src="imageHelpers.url(image.src, { width: 500, height: 500 })"
              :lazy-src="imageHelpers.url(image.src, { width: 500, height: 500 })"
              max-with="100%"
              max-height="250"
              min-height="100"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <!-- loading -->
            <v-row v-if="loading" class="loading-img fill-height ma-0 absolute" align="center" justify="center">
              <v-progress-circular indeterminate color="grey"></v-progress-circular>
            </v-row>
            <!-- overlay -->
            <v-fade-transition>
              <v-overlay v-if="hover" absolute color="#036358">
                <v-btn min-width="auto" width="auto" @click="clickUploadFile">Upload</v-btn>
                <v-btn
                  class="ml-1"
                  min-width="auto"
                  width="auto"
                  @click.stop="
                    showPreview = true;
                    preview.src = image.src;
                  "
                >
                  <v-icon x-small>fas fa-eye</v-icon>
                </v-btn>
                <v-btn
                  v-if="showAlt"
                  class="ml-1"
                  min-width="auto"
                  width="auto"
                  small
                  @click.stop="
                    showEditAlt = true;
                    preview.src = images.src;
                  "
                >
                  ALT
                </v-btn>
                <v-btn v-if="!hiddenDelete" class="ml-1" min-width="auto" width="auto" @click.stop="deleteFile(0)">
                  <v-icon x-small>mdi-delete</v-icon>
                </v-btn>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </template>

    <input
      v-if="multiple"
      ref="fileUploader"
      @change="onChangeImage"
      class="myfile"
      accept="image/gif, image/jpeg, image/png , image/jpg"
      type="file"
      multiple
    />
    <input
      v-else
      ref="fileUploader"
      @change="onChangeImage"
      class="myfile"
      accept="image/gif, image/jpeg, image/png , image/jpg"
      type="file"
    />
    <v-dialog v-model="showPreview" width="500">
      <v-card>
        <v-card-title class="headline lighten-2">{{ editable ? 'Preview' : 'Update' }}</v-card-title>
        <v-card-text>
          <v-text-field v-if="editable" v-model="preview.src" placeholder="URL" />
          <iframe
            v-if="isVideo"
            frameborder="0"
            width="100%"
            height="270"
            :src="embedUrl"
            style="background-color: #000;"
          />
          <v-img v-else contain max-height="600" :src="imageHelpers.url(preview.src, { width: 1000, height: 1000 })" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="editable" color="primary" @click="saveUrl">OK</v-btn>
          <v-btn v-else color="primary" text @click="showPreview = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import imageHelpers from '@/helpers/image';

const getEmbedUrl = url => {
  if (!url) return url;
  let matched = url.match(/^(https:\/\/)?(www\.)?youtube\.com\/(watch|embed).*$/);
  if (!matched) return url;
  let str = url.split(matched[matched.length - 1])[1];
  matched = str.match(/^\/([a-zA-Z0-9_-]+).*$/) || str.match(/^.*(\/|&|;|\?)+v=([a-zA-Z0-9_-]+).*$/);
  if (!matched) return url;
  return 'https://www.youtube.com/embed/' + matched[matched.length - 1] + '?rel=0&controls=0&showinfo=0&start=0';
};

export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    itemSetData: {
      type: Object,
      default: () => {},
    },
    hiddenDelete: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      default: function() {
        return {
          src: null,
        };
      },
    },
    maxWidth: {
      type: String,
      default: '450',
    },
    showAlt: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Object,
      default: function() {
        return { lg: 12, md: 12, sm: 12, xs: 12 };
      },
    },
  },
  data() {
    return {
      images: [],
      loadingImage: false,
      loading: false,
      imageHelpers,
      showPreview: false,
      preview: {
        alt: '',
        src: '',
      },
    };
  },
  computed: {
    isVideo() {
      let { src } = this.preview;
      return src && src.match(/^(https:\/\/)?(www\.)?youtube\.com\/(watch|embed).*$/);
    },
    embedUrl() {
      return getEmbedUrl(this.preview.src);
    },
  },
  methods: {
    saveUrl() {
      let src = this.embedUrl;
      this.showPreview = false;
      this.$emit('callback', { src }, this.itemSetData);
    },
    clickUploadFile() {
      this.resetImageUploader();
      this.$refs.fileUploader.click();
    },
    resetImageUploader() {
      this.$refs.fileUploader.value = '';
    },
    deleteFile() {
      this.$emit('callback', { src: null }, this.itemSetData);
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      let size = { width: 0, height: 0 };
      let inputTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      var _URL = window.URL || window.webkitURL;

      let img = new Image();
      var objectUrl = _URL.createObjectURL(e.target.files[0]);
      img.onload = function() {
        size.width = this.width;
        size.height = this.height;
      };
      img.src = _URL.createObjectURL(e.target.files[0]);

      if (inputTypes.includes(e.target.files[0].type)) {
        let file = e.target.files[0];
        try {
          let type = file.name.split('.')[file.name.split('.').length - 1];
          let id = this.objectId();
          let urlData = await uploadService.get({ filename: `lattehub-image-${id}.${type}` });
          if (urlData) {
            let res = await axios.put(urlData.data.presignedUrl, file, {
              headers: {
                'Content-Type': `${file.type}`,
              },
            });
            let obj = {
              alt: file.name,
              alt_text: file.name,
              src: urlData.data.imagePath,
              _id: id,
              size: size,
            };
            this.$emit('callback', obj, this.itemSetData);
          }
        } catch (error) {
          this.$store.commit('setMessages', {
            messages: 'Error image ',
            type: 'error',
          });
        }
      } else {
        alert('Type not support');
      }
    },
  },
};
</script>

<style lang="scss">
.file-upload-container {
  .v-card {
    border-radius: unset;
    background: #f5f6f7;
    box-shadow: none !important;
    margin: 0 auto;
  }
}
.upload-one-image {
  width: 100%;
  min-height: 150px;
  max-height: 400px;
  cursor: pointer;
  border: 1px dashed #4181cb;

  padding: 2px;
  .text-upload {
    font-weight: bold;
    color: #4181cb;
    font-size: 13px;
  }

  i {
    font-size: 15px !important;
    color: #4181cb;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px dashed #4181cb;
  border-radius: 6px;
  box-sizing: border-box;
  //   width: 80px;
  //   height: 45px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.upload-dragger {
  padding: 5px;
  .text-upload {
    font-weight: bold;
    color: #4181cb;
  }

  i {
    font-size: 40px;
    color: #4181cb;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px dashed #4181cb;
  border-radius: 6px;
  box-sizing: border-box;
  //   width: 140px;
  //   height: 80px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.myfile {
  display: none;
}
</style>
