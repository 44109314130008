import { AnalyticService } from '../../apis/analytics';
import { FETCH_ABCHECKOUT_CONVERSION } from '../actions.type';
import { FETCH_ABCHECKOUT_CONVERSION_END, FETCH_CONVERSION_RATE_END } from '../mutations.type';

const initialState = {
  abcheckoutConversion: {
    labels: ['SENT', 'CLICK', 'ORDER COMPLETED'],
    datasets: [
      {
        backgroundColor: 'rgb(255, 180, 14)',
        pointBorderColor: '#249EBF',
        data: [0, 0, 0],
        barThickness: 25,
      },
    ],
  },
  chartOptions: {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 100,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          ticks: {
            callback: function(label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(' ');
              } else {
                return label;
              }
            },
            maxRotation: 0,
            fontSize: 10,
            padding: 10,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        display: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1,
      onComplete: function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
    title: {
      display: true,
      fontSize: 12,
    },
  },
  completedOrder: 0,
  abcheckoutRevenue: 0,
  abcheckoutConversionRate: 0,
  abcheckoutTotalOrder: 0,
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_ABCHECKOUT_CONVERSION](context, args) {
    const { data } = await AnalyticService.fetchAbcheckoutConversion({ params: args });

    context.commit(FETCH_ABCHECKOUT_CONVERSION_END, data);
    return data;
  },
};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  [FETCH_ABCHECKOUT_CONVERSION_END](state, data) {
    state.abcheckoutConversion = {
      labels: ['SENT', 'CLICK', 'ORDER COMPLETED'],
      datasets: [
        {
          backgroundColor: 'rgb(255, 180, 14)',
          pointBorderColor: 'rgb(255, 180, 14)',
          data: [data['emailSent'], data['emailClick'], data['completedOrder']],
          barThickness: 25,
        },
      ],
    };
    state.completedOrder = data['completedOrder'];
    state.abcheckoutRevenue = data['revenue'].toFixed(2);
    state.abcheckoutTotalOrder = data['total'];
    state.abcheckoutConversionRate = (((data['completedOrder'] || 0) / (data['total'] || 1)) * 100).toFixed(2);
  },
};

const getters = {
  abcheckoutConversion() {
    return state.abcheckoutConversion;
  },
  abcheckoutChartOptions() {
    return state.chartOptions;
  },
  abcheckoutConversionRate() {
    return state.abcheckoutConversionRate;
  },
  abCheckoutRevenue() {
    return state.abcheckoutRevenue;
  },
  abcheckoutCompletedOrder() {
    return state.completedOrder;
  },
  abcheckoutTotalOrder() {
    return state.abcheckoutTotalOrder;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
