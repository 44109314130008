const state = {
  user: null,
  authCheck: false,
  accountInfo: {
    storeId: '',
    accessToken: '',
    refreshToken: '',
    storeDomain: '',
    host: '',
  },
  codeRefCreateStore: null,
};
const getters = {
  user: state => state.user,
  isAuthticated: state => !!state.user,
};
const mutations = {
  setAuthCheck(state, check) {
    state.authCheck = check ? true : false;
  },
  setUser(state, user) {
    state.user = user;
  },
  setAccountInfo(state, data) {
    state.accountInfo = { ...state.accountInfo, ...data };
  },
  setCodeRefCreateStore(state, data) {
    state.codeRefCreateStore = data;
  },
};

const actions = {};
export default {
  state,
  actions,
  mutations,
  getters,
};
