import { settingsApi } from '../../apis/settings';
import { FETCH_PAGE_PREFERRENCE_DONE } from '../mutations.type';
import { FETCH_PAGE_PREFERRENCE } from '../actions.type';

const state = {
  balance: 0,
  preference: {},
};

const actions = {
  async [FETCH_PAGE_PREFERRENCE](context) {
    const { data } = await settingsApi.getGeneral();
    if (data.language === 'vi') {
      localStorage.setItem('currencySign', 'đ');
      localStorage.setItem('currencyCode', 'VND');
    } else if (data.language === 'en') {
      localStorage.setItem('currencySign', '$');
      localStorage.setItem('currencyCode', 'USD');
    }
    localStorage.setItem(`FETCH_PAGE_PREFERRENCE`, JSON.stringify(data));
    context.commit(FETCH_PAGE_PREFERRENCE_DONE, data);
    return data;
  },
};

const getters = {
  pagePreference: state => state.preference,
};

const mutations = {
  'pagePreference/setBalance': (state, data) => {
    state.balance = Number(data);
  },
  [FETCH_PAGE_PREFERRENCE_DONE](state, preference) {
    localStorage.setItem('timeZone', preference.standardTimezone);
    state.preference = preference;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
