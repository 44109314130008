import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function getGeneral() {
  return storeRequest.get('/stores/settings/general');
}

function updateGeneral(params) {
  return storeRequest.put('/stores/settings/general', params);
}

function getLegal() {
  return storeRequest.get('/stores/settings/legal');
}

function updateLegal(params) {
  return storeRequest.put('/stores/settings/legal', params);
}

function getNotifications() {
  return storeRequest.get('/notifications/template/store-templates');
}

function updateNotifications(params) {
  return storeRequest.put('/notifications/template/store-templates/' + params.id, params);
}

function getNotificationsById(id) {
  return storeRequest.get('/notifications/template/store-templates/' + id);
}

function sendTestEmail(params) {
  return storeRequest.get('/notifications/email/send-test-email/' + params.id, params);
}

function getCheckout() {
  return storeRequest.get('/stores/settings/checkout');
}

function updateCheckout(data) {
  return storeRequest.put('/stores/settings/checkout', data);
}
function createCheckout(data) {
  return storeRequest.post('/stores/settings/checkout', data);
}
export const settingsApi = {
  getGeneral,
  updateGeneral,
  getLegal,
  updateLegal,
  getNotifications,
  updateNotifications,
  getNotificationsById,
  sendTestEmail,
  getCheckout,
  updateCheckout,
  createCheckout,
};
