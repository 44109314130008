export const FETCH_CONVERSION_RATE = 'fetch_conversion_rate';
export const FETCH_TOTAL_ORDERS = 'fetch_total_orders';
export const FETCH_TOTAL_SALES = 'fetch_total_sales';
export const FETCH_STORES = 'fetch_stores';
export const FETCH_AOV_RATE = 'fetch_aov_rate';
export const FETCH_AOI_RATE = 'fetch_aoi_rate';
export const FETCH_ABCHECKOUT_CONVERSION = 'fetch_abcheckout_conversion';

export const FETCH_PAGE_PREFERRENCE = 'fetch_page_preferrence';

export const FETCH_ALL_PRODUCTS = 'fetch_all_products';
export const FETCH_PRODUCT_SALES_REPORT = 'fetch_product_sales_report';

export const FETCH_REVIEWS = 'fetch_reviews';
export const FETCH_PRODUCT_OPTION_TEMPLATES = 'fetchProductOptionTemplates';
