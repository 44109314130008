export default [
  {
    path: 'sizechart',
    name: 'website.sizechart',
    component: () => import('@/views/website/sizechart/index'),
  },
  {
    path: 'sizechart/add',
    name: 'website.sizechart.add',
    component: () => import('@/views/website/sizechart/Add'),
  },
  {
    path: 'sizechart/update/:id',
    name: 'website.sizechart.update',
    component: () => import('@/views/website/sizechart/Update'),
  },
  {
    path: 'sizechart/testdialog',
    name: 'website.sizechart.testdialog',
    component: () => import('@/views/website/sizechart/TestDialog'),
  },
];
