const state = {
  messages: [],
  show: false,
  type: 'success',
  //error success warning
};
const getters = {
  messages: state => state.messages,
  show: state => state.show,
};
const mutations = {
  setMessages(state, data) {
    state.messages = data.messages;
    state.type = data.type;
    state.show = true;
  },
};

export default {
  state,
  mutations,
  getters,
};
