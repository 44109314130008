<template>
  <div class="form-component">
    <label v-if="showLabel" class="d-flex" :style="'font-size:' + size">{{ label }}</label>
    <v-btn name="select-product" depressed width="100%" :light="true" @click="handler" :left="true">{{ name }}</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '14px',
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    handler: Function,
  },
};
</script>

<style></style>
