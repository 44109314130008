export const FETCH_CONVERSION_RATE_END = 'setConversionRate';
export const FETCH_START = 'setLoading';
export const FETCH_TOTAL_ORDERS_END = 'setTotalOrders';
export const FETCH_TOTAL_SALES_END = 'setTotalSales';
export const FETCH_STORES_DONE = 'setStoresDone';
export const FETCH_AOV_RATE_DONE = 'setAverageOrderValue';
export const FETCH_AOI_RATE_DONE = 'setAverageOrderItems';
export const FETCH_PAGE_PREFERRENCE_DONE = 'setPagePreferrence';
export const FETCH_ABCHECKOUT_CONVERSION_END = 'setAbcheckoutConversion';

export const FETCH_ALL_PRODUCTS_DONE = 'setProducts';
export const FETCH_PRODUCT_SALES_REPORT_DONE = 'setProductSalesReport';
export const FETCH_REVIEWS_DONE = 'setReviews';
export const FETCH_PRODUCT_OPTION_TEMPLATES_DONE = 'setProductOptionTemplates';
