const noImageUer =
  'https://minio.lattehub.com/lattehub/605c62651288b96616c6babe/2021/09/03/lattehub-image-6131c84ec0d8a211cf0c58ae.jpg';
const variant =
  'https://minio.lattehub.com/lattehub/605c62651288b96616c6babe/2021/09/03/lattehub-image-6131c990a630ebae81221985.png';
const user =
  'https://minio.lattehub.com/lattehub/605c62651288b96616c6babe/2021/09/03/lattehub-image-6131c8ff5f491a48d6f6fcba.jpg';
const paid =
  'https://minio.lattehub.com/lattehub/6062b1cc5e49080211083cbe/2021/06/23/lattehub-image-60d29d6f9a951cc3916c3763.png';
const unfulfilled =
  'https://minio.lattehub.com/lattehub/609b8327a82fee2a7b976bc1/2021/09/03/lattehub-image-6131cae502e71e4e14dbfe9a.png';
const Logo =
  'https://minio.lattehub.com/img/500/500/resize/60e6d1a82935f80009d77d65/2021/07/23/lattehub-image-60fa4734c8ab6a0119229c20.png';
const Emty =
  'https://minio.lattehub.com/lattehub/605c62651288b96616c6babe/2021/09/03/lattehub-image-6131c924a1025afd24316d5c.png';
const LogoTheme =
  'https://minio.lattehub.com/img/200/200/resize/60d9a7f8454744683569f1e1/2021/08/20/lattehub-image-611f5c46756d7c5c50784cd3.png';
const BannerTheme =
  'https://minio.lattehub.com/lattehub/605c62651288b96616c6babe/2021/09/03/lattehub-image-6131c945bb271a9483f5abe4.gif';
const SectionsHelp =
  'https://minio.lattehub.com/lattehub/605c62651288b96616c6babe/2021/09/03/lattehub-image-6131c5ff7ac2b0dfc5572cad.png';
export const imageFake = {
  noImageUer,
  variant,
  user,
  paid,
  unfulfilled,
  Logo,
  Emty,
  LogoTheme,
  BannerTheme,
  SectionsHelp,
};
