<template>
  <date-range-picker
    @update="pickDate"
    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
    class="date-ranger calendar"
    :singleDatePicker="singleDatePicker"
    :zIndex="200000"
    :ranges="ranges"
    :appendToBody="true"
    :dateFormat="dateFormat"
    opens="left"
    v-model="dataDateRange"
    @toggle="checkToggle"
  >
  </date-range-picker>
</template>

<script>
import { log } from 'handlebars';
import utilities from '@/helpers/utilities';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { createLogger, mapState } from 'vuex';
import moment from 'moment';

// var moment = require('moment');
export default {
  name: 'disabled_dates',
  components: { DateRangePicker },
  data() {
    let today = moment();
    today.tz(this.timezone);

    let yesterday = moment();
    yesterday.set('date', today.get('date') - 1);

    let currDate = moment();
    currDate.tz(this.timezone);

    let lastS7Days = moment();
    lastS7Days.set('date', lastS7Days.get('date') - 7);
    lastS7Days.tz(this.timezone);

    let lastS90Days = moment();
    lastS90Days.set('date', lastS90Days.get('date') - 90);
    lastS90Days.tz(this.timezone);

    let firstDateOfWeek = moment().day(1);
    firstDateOfWeek.tz(this.timezone);

    let firstDateOfMonth = moment();
    firstDateOfMonth.set('date', 1);
    firstDateOfMonth.tz(this.timezone);

    return {
      showToggle: false,
      utilities,
      dataDateRange: {},
      isLoading: false,
      // firstDay,
      // moment: moment,
      today: today,
      show: true,
      yesterday: yesterday,
      lastWeek: '',
      ranges: {
        Today: [this.momentToDate(today), this.momentToDate(today)],
        Yesterday: [this.momentToDate(yesterday), this.momentToDate(yesterday)],
        'Last 7 days': [this.momentToDate(lastS7Days), this.momentToDate(today)],
        'Last 90 days': [this.momentToDate(lastS90Days), this.momentToDate(today)],
        'Week to date': [this.momentToDate(firstDateOfWeek), this.momentToDate(today)],
        'Month to date': [this.momentToDate(firstDateOfMonth), this.momentToDate(today)],
      },
    };
  },
  props: {
    clearD: {
      type: Number,
    },
    dateType: {
      type: String,
    },
    singleDatePicker: {
      type: Boolean,
      default: false,
    },
    dateRange: {
      type: Object,
      default: function() {
        return {
          startDate: '',
          endDate: '',
        };
      },
    },
    timezone: {
      type: String,
      default: 'America/Los_Angeles',
    },
  },
  methods: {
    checkToggle(showToggle) {
      // this.$emit('checkToggle', showToggle);
      // console.log(showToggle, 'logToggle');
      this.showToggle = showToggle;
    },
    clearDate() {
      this.datePicker.setValue(null);
      console.log('A');
    },
    //   return absolute
    // },
    momentToDate(time) {
      return new Date(time.format('YYYY-MM-DD HH:mm'));
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        let today = moment();
        today.tz(this.timezone);
        today.endOf('date');
        let todayDate = this.momentToDate(today.endOf('date'));
        classes.disabled = todayDate.getTime() < date.getTime();
      }
      return classes;
    },
    clickedApply() {
      console.log(1);
    },
    pickDate() {
      this.$parent.$parent.drawer = true;
      if (this.dateType == 'orderDate') {
        let dataDate = {
          startDate: JSON.stringify(this.dataDateRange.startDate).slice(1, 11),
          endDate: JSON.stringify(this.dataDateRange.endDate).slice(1, 11),
          timezone: this.pagePreference.standardTimezone,
        };
        this.$emit('getOrderDate', dataDate);
        console.log('order');
      }
      if (this.dateType == 'fulfillmentDate') {
        let dataDate1 = {
          startDate: JSON.stringify(this.dataDateRange.startDate).slice(1, 11),
          endDate: JSON.stringify(this.dataDateRange.endDate).slice(1, 11),
          timezone: this.pagePreference.standardTimezone,
        };
        this.$emit('getFulfillDate', dataDate1);
      }
      // let data = utilities.formatDate(dataDate);
      // this.$emit('changeDate', data);
    },
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  watch: {
    dateRange: function() {
      this.dataDateRange = this.dateRange;
      console.log('AAAAAAAAAAAAA');
    },
    clearD() {
      if (this.clearD == 1) {
        console.log(1);
      }
    },
    show() {
      console.log(this.show);
    },
  },
  created() {
    // console.log(this.show);
    this.dataDateRange = this.dateRange;
    // console.log(this.dataDateRange);
    if (this.singleDatePicker) {
      delete this.ranges['Last 7 days'];
      delete this.ranges['Last 30 days'];
      delete this.ranges['Last 90 days'];
    }
    console.log(this.dataDateRange);
  },
  // mounted() {
  //   this.dateRange.startDate = '';
  //   this.dateRange.endDate = '';
  // },
};
</script>
<style lang="scss">
.reportrange-text {
  background-color: #f5f6f7 !important;
  height: 35px !important;
  border-radius: 8px !important;
  margin: auto 0 !important;
}
.slot {
  padding: 15px 10px;
  border-bottom: 1px solid #e6e6e6;
}

.drp-buttons {
  padding-right: 40px !important;
  .applyBtn {
    background-color: #a98a57;
    color: white;
  }

  .btn {
    border: 1px solid #e6e6e6;
    border-radius: 7px;
    width: 60px !important;
    height: 40px !important;
    padding: 10px !important;
  }
}
.daterangepicker td.active {
  background-color: #a98a57;
}
.daterangepicker td.active:hover {
  background-color: #a98a57;
}
@media screen and (min-width: 339px) {
  .daterangepicker.single.show-ranges.show-weeknumbers,
  .daterangepicker.single.show-ranges {
    min-width: 380px !important;
  }
}
</style>
