<template>
  <div>
    <v-snackbar
      v-model="$store.state.noti.show"
      :color="$store.state.noti.type"
      mt-1
      top
      class="notify"
      :timeout="2000"
    >
      <span class="mt-2">
        <i class="mr-4 fas fa-exclamation-circle"></i>
        {{ $t($store.state.noti.messages) }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      type: 'success',
    };
  },
};
</script>
<style lang="scss">
.notify {
  .v-snack__content {
    width: 100%;
    justify-content: flex-start;
    height: 55px !important;
    font-size: 18px !important;
    margin-top: 5px;
  }
}
.icon-sn {
  font-size: 20px !important;
}
.v-snack--top {
  top: 2px;
}
</style>
