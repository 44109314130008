<template>
  <!-- Toggle -->
  <div class="d-flex align-center">
    <v-select
      style="max-width:120px"
      color
      v-model="limit"
      :items="validLimits"
      item-text="value"
      item-value="value"
      dense
    ></v-select>
    <span class="ml-2 mb-3">
      / page
    </span>
  </div>
</template>

<script type="text/javascript">
import FORMAT_APP from '@/const/format';
import { log } from 'handlebars';

export default {
  props: {
    /**
     * Sync limit to url query
     */
    syncLimitQuery: {
      type: Boolean,
      default: true,
    },
    limitAdd: {
      type: Number,
      default: 0,
    },
    /**
     * Callback
     */
    callback: {
      type: Function,
      default(limit) {
        console.log('Default limit handle. Received value: ' + limit);
      },
    },
  },

  data() {
    return {
      limit: null,
      validLimits: [{ value: 10 }, { value: 50 }, { value: 100 }, { value: 150 }, { value: 200 }],
    };
  },

  created() {
    this.syncFromQuery();
  },

  methods: {
    /**
     * Sync from query
     */
    syncFromQuery() {
      if (!this.syncLimitQuery || typeof this.$route.query.limit === 'undefined' || !this.$route.query.limit) {
        this.limit = FORMAT_APP.LIMIT_PAGE;
        return;
      }

      let limit = parseInt(this.$route.query.limit);

      if (limit === this.limit) {
        return;
      }

      this.limit = this.validLimits.indexOf(limit) === -1 ? 0 : limit;
    },

    /**
     * Sync to query
     */
    syncToQuery(limit) {
      if (!this.syncLimitQuery) {
        return;
      }

      let query = JSON.parse(JSON.stringify(this.$route.query));
      let changed = false;
      if (!limit) {
        if (typeof query.limit !== 'undefined') {
          delete query.limit;
          changed = true;
        }
      } else if (typeof query.limit === 'undefined' || parseInt(query.limit) !== limit) {
        // Sync to query
        query.limit = limit;
        changed = true;
      }

      if (!changed) {
        return;
      }
      // console.log(query);
      this.$router
        .replace({
          query,
        })
        .catch(() => {});
    },
  },

  watch: {
    $route: function() {
      this.syncFromQuery();
    },

    limit: function(limit) {
      // để khi change limit thì luôn back về pagination 1
      this.$emit('update:page', 1);
      this.syncToQuery(limit);
      this.callback(limit);
    },
  },
};
</script>
