import accountRequest from './request/accountRequest';
import STORAGE_NAME from '@/const/storage';
import storeRequest from './request/storeRequest';
let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
accountRequest.setStoreId(storeId);

function getCountries() {
  let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
  accountRequest.setStoreId(storeId);
  let token = localStorage.getItem('access-token');
  accountRequest.setToken(token);
  return accountRequest.get(`/stores/settings/countries`);
}

function survey(params) {
  let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
  accountRequest.setStoreId(storeId);
  let token = localStorage.getItem('access-token');
  accountRequest.setToken(token);
  return accountRequest.put(`/stores/survey`, params);
}

function list(params) {
  let token = localStorage.getItem('access-token');
  accountRequest.setToken(token);
  // let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  return accountRequest.get(`/users/stores`);
}

function clearCache() {
  let token = localStorage.getItem('access-token');
  storeRequest.setToken(token);
  return storeRequest.post(`/stores/cloudflare/clearCache`);
}

export const storeService = {
  getCountries,
  survey,
  list,
  clearCache,
};
