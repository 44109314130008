const STORAGE_NAME = {
  TOKEN: 'token',
  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',
  STORE_ID: 'store-id',
  STORE_DOMAIN: 'store-domain',
  AUTHORIZATION: 'Authorization',
  REPORT_SEARCH_QUERY: 'report-search-query',
  SALE_REPORT_QUERY: 'sale-report-query',
};

export default STORAGE_NAME;
