import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function create(data) {
  return storeRequest.post('/collections', {
    collection: data,
  });
}
function update(id, data) {
  return storeRequest.put('/collections/' + id, {
    collection: data,
  });
}
function get(params) {
  return storeRequest.get('/collections', {
    params: { ...params },
  });
}
function count(params) {
  return storeRequest.get('/collections/count', {
    params: { ...params },
  });
}
function getById(id) {
  return storeRequest.get('/collections/' + id);
}
function remove(id) {
  return storeRequest.delete('/collections/' + id);
}
function addProductToCollection(id, data) {
  return storeRequest.put(`/collections/${id}/collect`, {
    collection: { productIds: data },
  });
}
function removeProductToCollection(idCollection, idProduct) {
  return storeRequest.delete(`/collections/${idCollection}/products/${idProduct}`);
}
function removeCollections(params) {
  return storeRequest.delete(`collections/?ids=` + params.ids.join(','));
}

function updatePublicCollections(params, searchString, typeAll) {
  return storeRequest.patch(`collections/?` + `&search=${searchString}` + `&type=${typeAll}`, params);
}

function getLinkByHandle(params) {
  return storeRequest.get(`collections/byHandle/${params}`);
}

function reorder(id, body) {
  return storeRequest.put(`collections/reorder/${id}`, body);
}

export const collectionApi = {
  create,
  get,
  getById,
  update,
  remove,
  count,
  addProductToCollection,
  removeProductToCollection,
  removeCollections,
  updatePublicCollections,
  getLinkByHandle,
  reorder,
};
