import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import bookeditor from './modules/bookeditor';
import character from './modules/character';
import noti from './modules/noti';
import navigation from './modules/navigation';
import convertionRate from './modules/convertion-rate';
import totalOrders from './modules/total-orders';
import totalSales from './modules/total-sales';
import aov from './modules/aov';
import aoi from './modules/aoi';
import stores from './modules/stores';
import pagePreference from './modules/page-preferrence';
import product from './modules/product';
import printHub from './modules/print-hub';
import review from './modules/review';
import trafficSource from './modules/traffic-source';
import trafficlocation from './modules/traffic-location';
import abcheckout from './modules/abcheckout';
import subscription from './modules/subscription';
Vue.use(Vuex);

const store = () => {
  return new Vuex.Store({
    modules: {
      auth,
      bookeditor,
      character,
      noti,
      navigation,
      convertionRate,
      totalOrders,
      totalSales,
      aov,
      aoi,
      stores,
      pagePreference,
      product,
      review,
      printHub,
      trafficSource,
      trafficlocation,
      abcheckout,
      subscription,
    },
  });
};
export default store;
