import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
const storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

const subscriptionService = {
  list() {
    return storeRequest.get('/users/plan/subscriptions');
  },
  findById(id) {
    return storeRequest.get('/users/plan/subscriptions?id=' + id);
  },
  currentSubscription() {
    return storeRequest.get('/stores/plan/current-subscription');
  },
  getTrialTime() {
    return storeRequest.get('/stores/plan/trial-time');
  },
  updatePaymentInfo(payload) {
    return storeRequest.patch('/users/plan/payment-info', payload);
  },
  getPaymentInfo() {
    return storeRequest.get('/users/plan/payment-info');
  },
  startPlanSubscription(payload) {
    return storeRequest.post('/stores/plan/subscription', payload);
  },
};
export default subscriptionService;
