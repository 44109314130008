export default [
  {
    path: 'books/:id/:tab?',
    name: 'website.books',
    meta: {
      reload: true,
    },
    component: () => import('@/views/website/books'),
  },
];
