import Request from '../index';
import STORAGE_NAME from '@/const/storage';

const analyticRequest = new Request();
let token = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
analyticRequest.setBaseURL('https://' + storeDomain + '/api/report');
if (token) {
  analyticRequest.setToken(token);
}
export default analyticRequest;
