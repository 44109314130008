export default [
  {
    path: 'product-feeds',
    name: 'website.product-feeds',
    component: () => import('@/views/website/product-feeds'),
  },
  {
    path: 'product-feeds/create',
    name: 'website.product-feeds.create',
    component: () => import('@/views/website/product-feeds/Create'),
  },
  {
    path: 'product-feeds/update/:id',
    name: 'website.product-feeds.update',
    props: true,
    component: () => import('@/views/website/product-feeds/Update'),
  },
];
