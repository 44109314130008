export default [
  // Main Page
  {
    path: 'collections',
    name: 'website.collections',
    component: () => import('@/views/website/collections'),
  },
  {
    path: 'collections/update/:id',
    name: 'website.collections.update',
    component: () => import('@/views/website/collections/Update'),
  },
  {
    path: 'collections/create',
    name: 'website.collections.create',
    component: () => import('@/views/website/collections/Create'),
  },
];
