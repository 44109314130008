export default [
  // Main Page
  {
    path: 'list-app',
    name: 'website.apps',
    component: () => import('@/views/website/apps'),
  },
  {
    path: 'app/private',
    name: 'website.apps-private',
    component: () => import('@/views/website/apps/private-app'),
  },
  {
    path: 'app/private/new',
    name: 'website.apps-private.create',
    component: () => import('@/views/website/apps/private-app/Create'),
  },
  {
    path: 'app/private/:id',
    name: 'website.apps-private.edit',
    component: () => import('@/views/website/apps/private-app/Create'),
  },
  {
    path: 'app/migrate',
    name: 'website.apps-migrate',
    component: () => import('@/views/website/apps/migrate'),
  },
  {
    path: 'app/migrate/new',
    name: 'website.apps-migrate.create',
    component: () => import('@/views/website/apps/migrate/Create'),
  },
  {
    path: 'app/migrate/:id',
    name: 'website.apps-migrate.edit',
    component: () => import('@/views/website/apps/migrate/Create'),
  },
  {
    path: 'app',
    name: 'website.apps.upsell',
    component: () => import('@/views/website/apps/UpSell'),
    children: [
      {
        path: 'upsell/offer',
        name: 'website.apps.upsell.list',
        component: () => import('@/views/website/apps/UpSell/main-upsell/ListOffer'),
      },
      {
        path: 'upsell/offer/create',
        name: 'website.apps.upsell.create',
        component: () => import('@/views/website/apps/UpSell/main-upsell/CreateOffer'),
      },
      {
        path: 'upsell/offer/update/:id',
        name: 'website.apps.upsell.update',
        component: () => import('@/views/website/apps/UpSell/main-upsell/Update'),
      },
      {
        path: 'upsell/cross-sell/quantity-offer',
        name: 'website.apps.quantity.list',
        component: () => import('@/views/website/apps/UpSell/quantily/ListOffer'),
      },
      {
        path: 'upsell/cross-sell/quantity-offer/create',
        name: 'website.apps.quantity.create',
        component: () => import('@/views/website/apps/UpSell/quantily/CreateOffer'),
      },
      {
        path: 'upsell/cross-sell/quantity-offer/update/:id',
        name: 'website.apps.quantity.update',
        component: () => import('@/views/website/apps/UpSell/quantily/Update'),
      },
      {
        path: 'upsell/cross-sell/bundle',
        name: 'website.apps.bundle.list',
        component: () => import('@/views/website/apps/UpSell/bundles/ListOffer'),
      },
      {
        path: 'upsell/cross-sell/bundle/create',
        name: 'website.apps.bundle.create',
        component: () => import('@/views/website/apps/UpSell/bundles/CreateOffer'),
      },
      {
        path: 'upsell/cross-sell/bundle/update/:id',
        name: 'website.apps.bundle.update',
        component: () => import('@/views/website/apps/UpSell/bundles/Update'),
      },
      {
        path: 'upsell/cross-sell/widgets',
        name: 'website.apps.widgets.list',
        component: () => import('@/views/website/apps/UpSell/widgets'),
      },
      {
        path: 'upsell/cross-sell/widgets/handpicked',
        name: 'website.apps.widgets.handpicked',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked'),
      },
      {
        path: 'upsell/cross-sell/widgets/handpicked/create',
        name: 'website.apps.widgets.handpicked.create',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked/Create'),
      },
      {
        path: 'upsell/cross-sell/widgets/handpicked/update/:id',
        name: 'website.apps.widgets.handpicked.update',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked/Update'),
      },
      {
        path: 'upsell/cross-sell/widgets/cart-recommen/:widgetType',
        name: 'website.apps.widgets.cart-recommen',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked/Design'),
      },
      {
        path: 'upsell/cross-sell/widgets/cart-recommen/create',
        name: 'website.apps.widgets.cart-recommen.create',
        component: () => import('@/views/website/apps/UpSell/widgets/cart-recommen/Create'),
      },
      {
        path: 'upsell/cross-sell/widgets/handpicked/design/:widgetType',
        name: 'website.apps.widgets.handpicked.design',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked/Design'),
      },
      {
        path: 'upsell/cross-sell/widgets/same-collection/:widgetType',
        name: 'website.apps.widgets.same-collection',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked/Design'),
      },
      {
        path: 'upsell/cross-sell/widgets/same-collection/:widgetType',
        name: 'website.apps.widgets.pickForYou',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked/Design'),
      },
      {
        path: 'upsell/cross-sell/widgets/same-collection/:widgetType',
        name: 'website.apps.widgets.designCode',
        component: () => import('@/views/website/apps/UpSell/widgets/handpicked/Design'),
      },
      {
        path: 'upsell/cross-sell/gift-offer',
        name: 'website.apps.gift.list',
        component: () => import('@/views/website/apps/UpSell/gifts/index.vue'),
      },
      {
        path: 'upsell/cross-sell/gift-offer/create',
        name: 'website.apps.gift.create',
        component: () => import('@/views/website/apps/UpSell/gifts/CreateOffer'),
      },
      {
        path: 'upsell/cross-sell/gift-offer/update/:id',
        name: 'website.apps.gift.update',
        component: () => import('@/views/website/apps/UpSell/gifts/Update'),
      },
    ],
  },
  {
    path: 'app/product-review',
    name: 'website.apps.product-review',
    component: () => import('@/views/website/apps/product-review'),
    children: [
      {
        path: 'list',
        name: 'website.apps.product-review.list',
        component: () => import('@/views/website/apps/product-review/List'),
      },
      {
        path: 'list-product',
        name: 'website.apps.product-review.listProduct',
        component: () => import('@/views/website/apps/product-review/listProduct'),
      },
      {
        path: 'imports',
        name: 'website.apps.product-review.imports',
        component: () => import('@/views/website/apps/product-review/Imports'),
      },
      {
        path: 'imports-product',
        name: 'website.apps.product-review.importsProduct',
        component: () => import('@/views/website/apps/product-review/importProduct'),
      },
      {
        path: 'bulk',
        name: 'website.apps.product-review.bulk',
        component: () => import('@/views/website/apps/product-review/Bulk'),
      },
      {
        path: 'custome',
        name: 'website.apps.product-review.custome',
        component: () => import('@/views/website/apps/product-review/Custome'),
      },
      {
        path: 'settings',
        name: 'website.apps.product-review.settings',
        component: () => import('@/views/website/apps/product-review/Settings'),
      },
      {
        path: 'manage',
        name: 'website.apps.product-review.manage',
        component: () => import('@/views/website/apps/product-review/Manage'),
      },
    ],
  },
  {
    path: 'app/ali-connector',
    name: 'website.apps.ali-connector',
    component: () => import('@/views/website/apps/ali-connector'),
    children: [
      {
        path: 'import',
        name: 'website.apps.ali-connector.import',
        component: () => import('@/views/website/apps/ali-connector/Import'),
      },
    ],
  },
  {
    path: 'app/catalog-product',
    name: 'website.apps.print-hub',
    component: () => import('@/views/website/apps/print-on-demand'),
    children: [
      {
        path: 'catalog',
        name: 'website.apps.print-hub.catalog',
        component: () => import('@/views/website/apps/print-on-demand/catalog/Catalog'),
      },
      {
        path: 'campaigns',
        name: 'website.apps.print-hub.campaigns',
        component: () => import('@/views/website/apps/print-on-demand/campaign/index.vue'),
      },
      {
        path: 'campaigns/:id',
        name: 'website.apps.print-hub.campaigns.edit',
        component: () => import('@/views/website/apps/print-on-demand/campaign/EditCampaingn.vue'),
      },
      {
        path: 'campaigns/bulk-duplicate/:id',
        name: 'website.apps.print-hub.campaigns.bulk-duplicate',
        component: () => import('@/views/website/apps/print-on-demand/campaign/BulkDuplicateTool.vue'),
      },
      {
        path: 'artworks',
        name: 'website.apps.print-hub.artwork',
        component: () => import('@/views/website/apps/print-on-demand/library/ArtWork'),
      },
      {
        path: 'cliparts',
        name: 'website.apps.print-hub.cliparts',
        component: () => import('@/views/website/apps/print-on-demand/library/ClipArt'),
      },
    ],
  },
];
