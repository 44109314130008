export default [
  {
    path: 'bulkupdates',
    name: 'website.bulkupdates',
    component: () => import('@/views/website/bulkupdates'),
  },
  {
    path: 'bulkupdates/create',
    name: 'website.bulkupdates.create',
    component: () => import('@/views/website/bulkupdates/Create'),
  },
];
