import { AnalyticService } from '../../apis/analytics';

const state = {
  trafficLocation: [],
  total: 0,
  options: {
    total: 500,
  },
};

const getters = {
  getTrafficLocation: state => state.trafficLocation,
  getTotalTrafficLocation: state => state.total,
};
const actions = {
  trafficLocation({ commit }, args = {}) {
    console.log('=====>', args);
    return new Promise((resolve, reject) => {
      let strStores = Array.isArray(args.stores) ? args?.stores.join(',') : args.stores;
      AnalyticService.fetchTrafficLocation(args.fromTime, args.toTime, strStores, args?.timezone)
        .then(({ data }) => {
          commit('SET_TRAFFIC_LOCATION', data);
          return resolve({ message: 'success' });
        })
        .catch(ex => {
          return reject({ message: ex.message });
        });
    });
  },
};
const mutations = {
  SET_TRAFFIC_LOCATION(state, data) {
    let total = 0;
    data.map(item => {
      total += item.session_count.value;
    });
    state.total = total; //data.reduce((a, b) => a + b.session_count.value);
    state.trafficLocation = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
