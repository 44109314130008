export default [
  // Main Page
  {
    path: 'discount/code',
    name: 'website.discount.code',
    component: () => import('@/views/website/discount'),
  },
  {
    path: 'discount/automatic',
    name: 'website.discount.automatic',
    component: () => import('@/views/website/discount/Automatic'),
  },
  {
    path: 'discount/code/create',
    name: 'website.discount.code.create',
    component: () => import('@/views/website/discount/code/Create'),
  },
  {
    path: 'discount/code/update/:id',
    name: 'website.discount.code.update',
    props: true,
    component: () => import('@/views/website/discount/code/Update'),
  },
  {
    path: 'discount/automatic/create',
    name: 'website.discount.automatic.create',
    component: () => import('@/views/website/discount/automatic/Create'),
  },
  {
    path: 'discount/automatic/update/:id',
    name: 'website.discount.automatic.update',
    props: true,
    component: () => import('@/views/website/discount/automatic/Update'),
  },
];
