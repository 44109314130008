import Vue from 'vue';
import App from './App.vue';
import i18n from './lang';
import router from './router';
import Vuelidate from 'vuelidate';
import vuetify from '@/plugins/vuetify';
import VueMask from 'v-mask';
import store from './store';
import './assets/css/main.scss';
import '@/plugins/components';
import '@/plugins/config';
import 'moment-timezone';
import './filters/colorSwitcher';
import * as filters from '@/filters';
import VuePapaParse from 'vue-papa-parse';
import VueSelectImage from 'vue-select-image';
require('vue-select-image/dist/vue-select-image.css');
import DateRangePicker from 'vue2-daterange-picker';
Vue.use(VuePapaParse);
Vue.config.productionTip = false;
Vue.use(VueSelectImage);
Vue.use(DateRangePicker);
import VueKonva from 'vue-konva';
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);
Vue.use(VueKonva);
//quill
import VueQuillEditor from 'vue-quill-editor';
//placeholder

import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme

import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(CKEditor);

Vue.use(VueQuillEditor);
// jQuery
window.$ = $;
window.jQuery = $;

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

//Vuelidate
Vue.use(Vuelidate);
// Vue Mask
Vue.use(VueMask);
export const eventBus = new Vue();
new Vue({
  i18n,
  vuetify,
  store,
  router,
  render: h => h(App),
  errorCaptured(error) {
    // alert(error);
  },
}).$mount('#app');
