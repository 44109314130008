import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/orders/';

function getProfit(id) {
  return storeRequest.get('/report/orders/profit?orderId=' + id);
}

function refreshPB(id) {
  return storeRequest.post(`${path}${id}/pb/refresh`);
}

function getCountry(params) {
  return storeRequest.get('/stores/public/countries', {
    params: { ...params },
  });
}
function getpublic(id) {
  return storeRequest.get('orders/public' + id);
}
function get(params) {
  return storeRequest.get('/orders/orders', {
    params: { ...params },
  });
}
function getExport(params) {
  return storeRequest.get('/orders/doExport', {
    params: { ...params },
  });
}
function create(data) {
  return storeRequest.post('/orders', {
    product: data,
  });
}
function getCount(params) {
  return storeRequest.get('/orders/orders/count', {
    params: { ...params },
  });
}
function getById(id) {
  return storeRequest.get('/orders/orders/' + id);
}
function update(id, data) {
  return storeRequest.put('/orders/' + id, {
    product: data,
  });
}
function remove(id) {
  return storeRequest.delete('/orders/' + id);
}
function getFulfillmentByIdOrder(orderId) {
  return storeRequest.get(`${path}${orderId}/fulfillments`);
}

function createFulfillment(orderId, data) {
  return storeRequest.post(`${path}${orderId}/fulfillments`, {
    fulfillment: data,
  });
}
function updateFulfillment(orderId, fulfillId, data) {
  return storeRequest.put(`${path}${orderId}/fulfillments/${fulfillId}`, {
    fulfillment: data,
  });
}
function cancelFulfillment(orderId, fulfillId, data) {
  return storeRequest.put(`${path}${orderId}/fulfillments/${fulfillId}/cancel`);
}
// timeline
function getTimelineByOrderId(orderId, tz) {
  // console.log(`/orders/` + orderId + `/history/` + '?timeZone=' + tz);
  // return storeRequest.get(`/orders/` + orderId + `/history/` + '?timeZone=' + tz);
  return storeRequest.get(`/orders/` + orderId + `/history/`);
}
function createTimelineByOrderId(orderId) {
  return storeRequest.post(`/orders/history`);
}
// refund
function getRefundedByOrderId(orderId) {
  return storeRequest.get(`/orders/` + orderId + `/refund`);
}
function createRefunded(data) {
  return storeRequest.post(`/orders/refund`, data);
}
function cancelOrder(data) {
  return storeRequest.post(`/orders/cancel`, data);
}
export const orderApi = {
  getProfit,
  refreshPB,
  getCountry,
  getpublic,
  create,
  get,
  getCount,
  getById,
  update,
  remove,
  createFulfillment,
  updateFulfillment,
  cancelFulfillment,
  getFulfillmentByIdOrder,
  getRefundedByOrderId,
  getTimelineByOrderId,
  createTimelineByOrderId,
  createRefunded,
  cancelOrder,
  getExport,
};
