<template>
  <div class="">
    <v-card>
      <v-card-title class="">
        <h4>Select countries</h4>
      </v-card-title>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-container v-if="!isLoading" class="">
          <v-row class="l-dialog__body">
            <v-col cols="12" sm="6" class="l-dialog-right">
              <div class="search-box d-flex align-center">
                <v-text-field v-model="params.search" hide-details="" placeholder="Enter keyword to search">
                  <template v-slot:append>
                    <i class="mt-2 mr-2 fz-14 fas fa-search"> </i>
                  </template>
                </v-text-field>
                <i
                  v-if="isIconAddAll"
                  @click="checkAll"
                  class="px-2 mt-1 mdi mdi-plus-circle-multiple-outline mdi-24px pointer m"
                ></i>
              </div>

              <v-divider class=""></v-divider>
              <div id="selector" ref="selector" class="selector mt-2 pt-1 px-1">
                <template v-if="availableCountries.length > 0">
                  <div v-for="(i, k) in availableCountries" :key="i._id" class="mb-2">
                    <div class="d-flex align-center">
                      <span class="ml-3 link-add">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <h4 v-bind="attrs" v-on="on">{{ utilities.stringLimit(i.name, 30) }}</h4>
                          </template>
                          <span>{{ i.name }}</span>
                        </v-tooltip>
                      </span>
                      <v-spacer />
                      <i class="fz-20 mdi mdi-plus-circle-outline mdi-24px pointer" @click="addSelection(i, k)"></i>
                    </div>
                  </div>
                </template>
                <!-- <Empty v-else /> -->
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="l-dialog-left">
              <div class="select-product-box d-flex align-center">
                <h3>Selected countries ({{ selected.length }})</h3>
                <!-- <img :src="removeAll" alt="" srcset="" /> -->
                <v-spacer />
                <i v-if="isIconRemoveAll" @click="removeAll" class="fas fa-eraser fz-20 pointer"></i>
              </div>
              <v-divider class=""></v-divider>
              <div class="selected mt-2 pt-1 px-1 ">
                <template v-if="selected.length > 0">
                  <div v-for="(i, k) in selected" :key="i._id" class="mb-2">
                    <div class="d-flex align-center">
                      <span class="ml-3 link-add">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <h4 v-bind="attrs" v-on="on">{{ utilities.stringLimit(i.name, 30) }}</h4>
                          </template>
                          <span>{{ i.name }}</span>
                        </v-tooltip>
                      </span>
                      <v-spacer />
                      <i class="mdi mdi-minus-circle-outline mdi-24px pointer" @click="removeSelection(i, k)"></i>
                    </div>
                  </div>
                </template>
                <Empty v-else />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submitSelection"> Continue with selected countries </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import utilities from '@/helpers/utilities';
import Countries from '@/mixins/country';
export default {
  mixins: [Countries],
  components: {},
  props: {
    isSuperShow: {
      type: Boolean,
      default: false,
    },
    isIconAddAll: {
      type: Boolean,
      default: true,
    },
    isIconRemoveAll: {
      type: Boolean,
      default: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    dataSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      imageHelpers,
      imageFake,
      selected: [],
      utilities,
      init: true,
      params: {
        search: '',
      },
      limit: 5,
      executor: null,
    };
  },
  created() {
    this.init = true;
    if (this.$route.params.id || this.isSuperShow) {
      this.selected = JSON.parse(JSON.stringify(this.dataSelected));
    } else {
      this.selected = [];
    }

    this.init = false;
  },
  computed: {
    availableCountries: function() {
      return this.countries.filter(country => {
        let isSelected = this.selected.find(selected => country?.code === selected?.code) !== undefined;
        let matchSearching = this.params.search
          ? country.name.toLowerCase().indexOf(this.params.search.toLowerCase()) !== -1
          : true;
        return matchSearching && !isSelected;
      });
    },
  },
  watch: {
    'params.search': function() {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(() => {
        this.params.page = 1;
      }, 400);
    },
    dialog() {
      this.init = false;
      if (this.dialog) {
        if (this.$route.params.id || this.isSuperShow) {
          this.selected = JSON.parse(JSON.stringify(this.dataSelected));
        } else {
          this.selected = [];
        }
      } else {
        // Auto apply selected countries if clickout popup
        this.params.search = '';
        this.$emit('returnData', this.selected);
        this.$emit('update:dialog', false);
      }
    },
  },
  methods: {
    submitSelection() {
      this.$emit('returnData', this.selected);
      this.$emit('update:dialog', false);
    },
    checkAll() {
      this.selected = this.selected.concat(this.availableCountries);
    },
    removeAll() {
      this.selected = [];
      this.sortAlphabet();
    },
    addSelection(i, k) {
      this.selected.push(i);
    },
    removeSelection(i, k) {
      this.selected.splice(k, 1);
      this.sortAlphabet();
    },
    sortAlphabet() {
      this.countries.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>
<style lang="scss">
.l-dialog {
  .l-dialog__body {
    min-height: 600px;
    border: 1px solid #dddd !important;
    border-radius: 10px;

    .l-dialog-right {
      .search-box {
        height: 50px;
      }
      .selector {
        max-height: 600px;
        overflow-y: auto;
      }
    }
    .l-dialog-left {
      border-left: 1px solid #dddd;
      .select-product-box {
        height: 50px;
      }
      .selected {
        max-height: 600px;
        overflow-y: auto;
      }
    }
  }
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar {
    width: 1px;
    height: 30px;
    visibility: hidden;
  }

  /* Track */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-track {
    background: #dddddddd;
  }

  /* Handle */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-thumb {
    background: #dddddddd;
  }

  /* Handle on hover */
  .selected::-webkit-scrollbar,
  .selector::-webkit-scrollbar-thumb:hover {
    background: #dddddddd;
  }
}
</style>
