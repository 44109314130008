export default [
  // Main Page
  {
    path: 'domain',
    name: 'website.domain',
    component: () => import('@/views/website/domain/Index'),
  },
  {
    path: 'domain/connect',
    name: 'website.domain.connect',
    component: () => import('@/views/website/domain/Connect'),
  },
  {
    path: 'domain/search',
    name: 'website.domain.search',
    component: () => import('@/views/website/domain/Search'),
  },
];
