import Vue from 'vue';

// Loading
import Loading from '@/components/Loading';
import Pagination from '@/components/Pagination';
import FilterMoreDrawer from '@/components/FilterMoreDrawer';
import HeaderTableTabs from '@/components/HeaderTableTabs';
import BeforeLeavePage from '@/components/dialog/BeforeLeavePage';
import GroupSubmit from '@/components/form/GroupSubmit';
import InputNest from '@/views/website/components/inputs/InputNest';
import InputDefault from '@/views/website/components/inputs/InputDefault';
import LatteBtn from '@/views/website/components/buttons/LatteBtn';
import CountrySelector from '@/components/CountrySelector';

import Snackbar from '@/components/Snackbar';
import SkeletonLoaders from '@/components/SkeletonLoaders';
import FileUploader from '@/components/form/FileUploader';
import Modal from '@/components/Modal';
import Notification from '@/components/Notification';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component('datetimepicker', DatePicker);
import ImagesUploader from '@/components/form/ImagesUploader';
import UploadOneImage from '@/components/form/UploadOneImage';
import UploadMultiple from '@/components/form/UploadMultiple';
import LimitPage from '@/components//LimitPage';
import Empty from '@/components//Empty';
import { Sketch } from 'vue-color';

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
Vue.component('sketch-picker', Sketch);

Vue.component('Empty', Empty);

Vue.component('limit-page', LimitPage);

Vue.component('UploadOneImage', UploadOneImage);
Vue.component('UploadMultiple', UploadMultiple);

import DeleteAction from '@/components/dialog/DeleteAction';
Vue.component('delete-action', DeleteAction);

Vue.component('images-uploader', ImagesUploader);
Vue.component('FileUploader', FileUploader);

Vue.component('modal', Modal);
Vue.component('Snackbar', Snackbar);
Vue.component('SkeletonLoaders', SkeletonLoaders);

import Redirect from '@/components/RedirectTo';
Vue.component('redirect', Redirect);
// eslint-disable-next-line prettier/prettier
Vue.component('FilterMoreDrawer', FilterMoreDrawer);

Vue.component('InputNest', InputNest);
Vue.component('InputDefault', InputDefault);
Vue.component('LatteBtn', LatteBtn);
Vue.component('CountrySelector', CountrySelector);

Vue.component('page-loader', Loading);
Vue.component('pagination', Pagination);
Vue.component('HeaderTableTabs', HeaderTableTabs);
Vue.component('BeforeLeavePage', BeforeLeavePage);
Vue.component('GroupSubmit', GroupSubmit);
Vue.component('Notification', Notification);
Vue.component('PrismEditor', PrismEditor);
// Vue.component('Datetime', Datetime);
