export default [
  // Main Page
  {
    path: 'themes',
    name: 'website.themes',
    component: () => import('@/views/website/themes'),
  },
  {
    path: 'themes/editor/:themeId',
    name: 'website.themes.editor',
    component: () => import('@/views/website/themes/Editor'),
  },
];
