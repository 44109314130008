export default [
  {
    path: 'product-personalized',
    name: 'website.product-personalized',
    component: () => import('@/views/website/product-personalized'),
  },
  {
    path: 'product-personalized/create/:id',
    name: 'website.product-personalized.create',
    component: () => import('@/views/website/product-personalized/Create'),
  },
  {
    path: 'product-personalized/update/:id',
    name: 'website.product-personalized.update',
    props: true,
    component: () => import('@/views/website/product-personalized/Update'),
  },
  {
    path: 'product-personalized/detail/:id',
    name: 'website.product-personalized.detail',
    props: true,
    component: () => import('@/views/website/product-personalized/Detail'),
  },
];
