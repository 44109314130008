<template>
  <div class="text-center">
    <!-- <img :width="size.width" :height="size.height" :src="imageFake.Emty" alt="" srcset="" /> -->
    <svg width="192" height="128" viewBox="0 0 192 128" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <path fill="#E9EBED" d="M138.049 43.407L95.873 33.248v79.494l42.176-11.04z"></path>
        <path fill="#DFE0E2" d="M53.696 43.407l42.177-10.159v79.494l-42.177-10.82z"></path>
        <path fill="#F5F6F7" d="M95.52 33.6l22.214-18.459 42.174 10.822-22.211 17.794z"></path>
        <path fill="#E0E0E0" d="M32.408 26.534l21.64 17.223 42.175-10.156-21.418-18.107z"></path>
        <path fill="#EEF0F1" d="M32.058 26.184l22.166 17.573L96.4 33.601 74.453 15.14z"></path>
        <path fill="#E9EBED" d="M53.696 43.407v58.516l42.177 10.819.569-58.074z"></path>
        <path fill="#DFE0E2" d="M138.049 101.702l-42.176 11.04V54.668l42.176-11.261z"></path>
        <path fill="#000" opacity=".06" d="M121.266 82.269v19.212l16.783-4.639v-19.21z"></path>
        <path fill="#000" opacity=".04" d="M53.145 73.769l23.627 5.962 18.991-15.458v-9.495L53.587 44.179z"></path>
        <path fill="#000" opacity=".04" d="M138.38 73.769l-23.628 5.962-18.989-15.458v-9.495l42.174-10.599z"></path>
        <path fill="#F5F6F7" d="M53.696 43.407L32.058 61.293l42.174 10.598 21.64-17.223z"></path>
        <path fill="#E0E0E0" d="M138.049 43.407l21.86 17.886-41.956 10.598-22.08-17.223z"></path>
        <path fill="#EEF0F1" d="M138.049 43.407l21.86 17.886-41.956 10.598-22.08-17.223z"></path>
      </g>
    </svg>
    <div class="d-flex justify-center">{{ title }}</div>
  </div>
</template>

<script>
import { imageFake } from '@/const/image';

export default {
  data() {
    return { imageFake };
  },
  props: {
    title: {
      type: String,
      default: 'No Data',
    },
    size: {
      type: Object,
      default: () => {
        return { width: '100', height: '100' };
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
