export default [
  // Main Page
  {
    path: 'orders',
    name: 'website.orders',
    component: () => import('@/views/website/orders'),
  },
  {
    path: 'orders/detail/:id',
    name: 'website.orders.detail',
    component: () => import('@/views/website/orders/Detail'),
  },
  {
    path: 'orders/:id/fulfillment',
    name: 'website.orders.fulfillment',
    component: () => import('@/views/website/orders/Fulfillment'),
  },
  {
    path: 'orders/:id/refund',
    name: 'website.orders.refund',
    component: () => import('@/views/website/orders/components/Refund'),
  },
];
