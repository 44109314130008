import Request from '../index';
import STORAGE_NAME from '@/const/storage';

const accountRequest = new Request();
let token = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);

if (token) {
  accountRequest.setToken(token);
}
export default accountRequest;
