import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

export const importCsvReview = data => {
  return storeRequest.post('/reviews/import', data);
};
export const importProductReview = (data, productId) => {
  return storeRequest.post('/reviews/import?type=product&productid=' + productId, data);
};
export const exportAllReviews = () => {
  return storeRequest.get('/reviews/export');
};

export const getReviews = queries => {
  return storeRequest.get('/reviews/admin', {
    params: queries,
  });
};

export const deleteReviews = queries => {
  console.log(queries);
  return storeRequest.delete('/reviews', queries);
};

export const updateReview = review => {
  return storeRequest.put('/reviews/' + review._id, review);
};
