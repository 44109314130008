const initialState = {
  list: [],
  openCatalog: false,
  listApiCalled: [],
  catalog: [],
  artwork: [],
  mockupType: [],
  mockupSelected: null,
  listMockupSelected: [],
};

export const state = { ...initialState };

export const actions = {};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  setList(state, list) {
    state.list = list;
  },
  setMockupSelected(state, data) {
    state.mockupSelected = data;
  },
  setListMockupSelected(state, data) {
    state.listMockupSelected = data;
  },
  updateList(state, list) {
    state.list = [...state.list, list];
  },
  setDialogCatalog(state, bool) {
    state.openCatalog = bool;
  },
  setListApiCalled(state, api) {
    state.listApiCalled.push(api);
  },
  setCatalog(state, catalog) {
    state.catalog = catalog;
  },
  updateListApiCalled(state, obj) {
    state.listApiCalled[obj.index].change = obj.bool;
  },
  setArtwork(state, artwork) {
    state.artwork = artwork;
  },
  updateArtwork(state, artwork) {
    console.log(artwork);
    state.artwork.unshift(artwork);
  },
  setMockupType(state, mockup) {
    state.mockupType = mockup;
  },
  delArtwork(state, id) {
    let index = state.artwork.findIndex(item => item._id === id);
    if (index > -1) {
      state.artwork.splice(index, 1);
    }
  },
};

const getters = {
  getList(state) {
    return state.list;
  },
  isOpenCatalog(state) {
    return state.openCatalog;
  },
  apiCalled(state) {
    return state.listApiCalled;
  },
  catalog(state) {
    return state.catalog;
  },
  artwork(state) {
    return state.artwork;
  },
  mockupType(state) {
    return state.mockupType;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
