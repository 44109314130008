<template>
  <v-navigation-drawer
    class="filter-component"
    mobile-breakpoint="25000"
    width="350"
    :disable-resize-watcher="true"
    :disable-route-watcher="true"
    right
    :value="drawer"
    @input="checkDrawer"
    fixed
    app
  >
    <div class="pl-2 label-filter d-flex align-center justify-space-between">
      <h3>
        <span>Filters</span>
      </h3>
      <span @click="$parent.drawer = false" class="mr-4 pointer"><h3>X</h3></span>
    </div>
    <div class="filter-list">
      <div justify="center" class="container-filter" style="display:block !important">
        <v-expansion-panels>
          <v-expansion-panel v-for="(field, i) in fields" :key="i">
            <v-expansion-panel-header>
              <div class="header">
                <div>{{ field.label }}</div>
                <div class="mt-3" v-if="filterData.find(f => f.field == field.field) && field.type.name == 'radio'">
                  <v-chip @click:close="removeFilterData(field, i)" close>
                    {{ filterData.find(f => f.field == field.field).value }}
                  </v-chip>
                </div>
                <div class="mt-3" v-if="filterData.find(f => f.field == field.field) && field.type.name == 'email'">
                  <v-chip @click:close="removeFilterData(field, i)" close>
                    {{ filterData.find(f => f.field == field.field).value }}
                  </v-chip>
                </div>
                <div class="mt-3" v-if="filterData.find(f => f.field == field.field) && field.type.name == 'country'">
                  <v-chip @click:close="removeFilterData(field, i)" close>
                    {{ filterData.find(f => f.field == field.field).nameDislay }}
                  </v-chip>
                </div>
                <div class="mt-3" v-if="filterData.find(f => f.field == field.field) && field.type.name == 'checkbox'">
                  <!-- <v-chip v-for="(item, ind) in filterData" :key="ind" class="mr-2 mb-2"> -->
                  <v-chip class="mr-2 mb-2" v-if="filterData.find(f => f.field == field.field).value != ''">
                    {{
                      filterData
                        .find(f => f.field == field.field)
                        .value.join(', ')
                        .replaceAll('_', ' ')
                    }}
                  </v-chip>
                </div>
                <div
                  class="mt-3"
                  v-if="filterData.find(f => f.field == field.field) && field.type.name == 'autocomplete'"
                >
                  <v-chip @click:close="removeFilterData(field, i)" close class="mb-2 mr-1">
                    <!-- {{ filterData.find(i => i.field == field.field).value }} -->
                    {{ convertIdCollectionToTitle(filterData.find(i => i.field == field.field)) }}
                  </v-chip>
                  <!-- <v-chip>{{ filterData[0].value.join(' ,') }}</v-chip> -->
                  <div v-if="field.model">
                    <v-btn @click="clear(field)" class="btn-link">Clear</v-btn>
                  </div>
                </div>
                <div class="mt-3" v-if="filterData.find(f => f.field == field.field) && field.type.name == 'compare'">
                  <v-chip @click:close="removeFilterData(field, i)" close>
                    {{ field.values.find(f => f.value == filterData.find(f => f.field == field.field).compare).name }}
                    : {{ filterData.find(f => f.field == field.field).value }}
                  </v-chip>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- <v-text-field placeholder="condition" @change="addCondition(field)"></v-text-field> -->
              <v-radio-group v-if="field.type.name == 'radio'" v-model="field.model" @change="addCondition(field)">
                <v-radio
                  v-for="(item, index) in field.values"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
              <div class="order-date" v-if="field.field == 'orderDate'">
                <DateRange
                  ref="usernameInputtt"
                  class="order-date-calender"
                  :timezone="timezone"
                  dateType="orderDate"
                  v-on:getOrderDate="getOrderDate"
                  :dateRange="originalDate"
                ></DateRange>
                <!-- <v-date-picker v-model="dates" range></v-date-picker> -->
              </div>
              <div v-if="field.field == 'fulfillmentDate'">
                <DateRange
                  dateType="fulfillmentDate"
                  :dateRange="originalDate"
                  :timezone="timezone"
                  v-on:getFulfillDate="getFulfillDate"
                />
              </div>
              <div v-if="field.field == 'fulfillmentDateFromTo'">
                <DateRange dateType="fulfillmentDateFromTo" @getFulFillDate="getDate" />
              </div>
              <div v-if="field.type.name == 'checkbox'">
                <v-checkbox
                  class="mt-1"
                  hide-details
                  v-for="(item, ind) in field.values"
                  :key="ind"
                  :label="item.name"
                  :value="item.value"
                  v-model="field.model"
                  multiple
                  @change="addCondition(field)"
                ></v-checkbox>
              </div>
              <div v-if="field.type.name == 'order-name'">
                <v-row>
                  <v-col sm="6"
                    ><v-text-field
                      @change="addCondition(field)"
                      prefix="From"
                      v-model="field.model0"
                      hide-details
                      dense
                      solo
                      flat
                      border
                      type="number"
                    ></v-text-field
                  ></v-col>
                  <v-col sm="6"
                    ><v-text-field
                      @change="addCondition(field)"
                      prefix="to"
                      v-model="field.model1"
                      hide-details
                      dense
                      solo
                      flat
                      border
                      type="number"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <!-- <v-btn color="red" class="white--text">Clear</v-btn> -->
              </div>
              <v-autocomplete
                v-model="field.model"
                v-if="field.type.name == 'autocomplete' && field.field == 'productType'"
                :search-input.sync="searchProductType"
                @click="loadItems(field)"
                @change="addCondition(field)"
                :items="field.items"
                :item-text="field.type.text"
                :item-value="field.type.value"
              >
                <template v-slot:selection></template>
              </v-autocomplete>
              <v-autocomplete
                v-model="field.model"
                v-if="field.type.name == 'autocomplete' && field.field == 'tags'"
                :search-input.sync="searchProductTag"
                @click="loadItems(field)"
                @change="addCondition(field)"
                :items="field.items"
                :item-text="field.type.text"
                :item-value="field.type.value"
              >
                <template v-slot:selection></template>
              </v-autocomplete>
              <v-autocomplete
                v-model="field.model"
                v-if="field.type.name == 'autocomplete' && field.field == 'collectionIds'"
                @click="loadItems(field)"
                @change="addCondition(field)"
                :items="field.items"
                :item-text="field.type.text"
                :item-value="field.type.value"
              >
                <template v-slot:selection></template>
              </v-autocomplete>
              <v-autocomplete
                v-model="field.model"
                v-if="field.type.name == 'autocomplete' && field.field == 'vendor'"
                @click="loadItems(field)"
                @change="addCondition(field)"
                :items="field.items"
                :item-text="field.type.text"
                :item-value="field.type.value"
              >
                <template v-slot:selection></template>
              </v-autocomplete>
              <v-autocomplete
                v-model="field.model"
                v-if="field.type.name == 'country'"
                @change="addCondition(field)"
                :items="country"
                :item-value="'code'"
                :item-text="'name'"
                @click="loadItems(field)"
              >
                <!-- {{ field.model }} -->
                <!-- <template v-slot:selection></template> -->
              </v-autocomplete>
              <div v-if="field.type.name == 'email'">
                <v-text-field
                  @change="addCondition(field)"
                  v-model="field.model"
                  v-if="field.type.name == 'email'"
                ></v-text-field>
              </div>
              <div v-if="field.type.name == 'compare'">
                <v-radio-group
                  v-for="(item, index) in field.values"
                  :key="index"
                  v-model="field.compare"
                  hide-details
                  @change="addCondition(field)"
                >
                  <v-radio :label="item.name" :value="item.value"></v-radio>
                  <v-text-field
                    @change="addCondition(field)"
                    v-model="field.model"
                    :type="field.type.number ? 'number' : 'string'"
                    v-if="field.compare === item.value"
                  ></v-text-field>
                </v-radio-group>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div class="filter-footer">
      <v-card class="pa-5 d-flex" max-width>
        <v-btn color="error" :disabled="offClear" @click="clearFilter">Clear</v-btn>
        <!-- <v-btn color="error" @click="clearFilter">Clear</v-btn> -->
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="doneSelectFilter">Done</v-btn>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { collectionApi } from '@/apis/collection';
import { orderApi } from '@/apis/order';
import { productApi } from '@/apis/product';
import DateRange from '@/components/DateRange';
import { log } from 'handlebars';
import utilities from '@/helpers/utilities';

export default {
  // mixins: [country],
  props: {
    isTable: {
      type: Boolean,
      default: false,
    },
    drawer: {
      type: Boolean,
      default: false,
    },
    /**
     * Fields
     */
    fields: {
      type: Array,
      default() {
        return [
          {
            label: 'Example Text',
            field: 'example-text',
            operators: ['='],
          },
          {
            label: 'Example Select',
            field: 'example-select',
            operators: ['=', '!=', '>', '<', '>=', '<='],
            options: {
              key: 'Value',
            },
          },
        ];
      },
    },
    /**
     * Preset filter data
     */
    filter: {
      default: null,
    },
    /**
     * Callback
     */
    callback: {
      type: Function,
      default(filterData) {
        console.log('Default filter data handler. ' + JSON.stringify(filterData));
      },
    },
    callbackDone: {
      type: Function,
      default(filterData) {
        console.log('Default filter data handler. ' + JSON.stringify(filterData));
      },
    },
    timezone: String,
  },
  components: {
    DateRange,
  },
  data() {
    return {
      // dates: ['2019-09-10', '2019-09-20'],
      // clearD: 0,
      // checkTogglee: true,
      listCollection: [],
      collectionSelected: '',
      searchProductType: '',
      searchProductTag: '',
      singleDatePicker: true,
      offClear: true,
      utilities,
      filterData: [],
      isLoading: false,
      items: [],
      country: [],
      isArray: [],
      isNotArray: [],
      isCheck: false,
      originalDate: {
        startDate: '',
        endDate: '',
      },
    };
  },
  // computed: {
  //   clearD() {
  //     return 0;
  //   },
  // },
  computed: {
    keepOpen() {
      return this.onClickOutside;
    },
  },
  created() {
    // console.log(this.$refs['usernameInputtt']);
    // console.log(this.fields);
    if (this.$route.query && this.$route.query._query) {
      let query = JSON.parse(this.$route.query._query);
      this.filterData = query;
      this.callback(this.filterData);
      console.log('filterData', this.filterData);
      console.log('fields', this.fields);
      this.callbackDone();
      let collectionField = this.fields.find(field => field.field == 'collectionIds');
      if (collectionField) {
        this.loadCollections(collectionField);
      }
      let tagsField = this.fields.find(field => field.field == 'tags');
      if (tagsField) {
        this.loadProductTags(tagsField);
      }
      let productTypeField = this.fields.find(field => field.field == 'productType');
      if (productTypeField) {
        this.getProductTypes(productTypeField);
      }
      let vendorField = this.fields.find(field => field.field == 'vendor');
      if (vendorField) {
        this.getProductVendors(vendorField);
      }
    }
    this.getCountry();
    this.clearClicked();
  },
  mounted() {
    // clear filter khi chuyển màn
    let vm = this;
    this.fields.forEach(field => {
      // dành cho field khác order-number, có model == ''
      // if (field.model === '') {
      //   vm.$set(field, 'model', '');
      // }
      if (field.field !== 'orderName' && field.scoped !== 'order') {
        vm.$set(field, 'model', '');
      } else if (field.scoped === 'order') {
        vm.$set(field, 'model', '');
      } else {
        // dành cho field là order-number, có model == []
        vm.$set(field, 'model0', '');
        vm.$set(field, 'model1', '');
      }
    });
  },
  watch: {
    searchProductType() {
      // this.keyUpAt = new Date().getTime();
      // setTimeout(() => {
      //   let now = new Date().getTime();
      //   if (this.keyUpAt && now > this.keyUpAt + 800) {
      //     this.keyUpAt = null;
      //     let productTypeField = this.fields.find(field => field.field == 'productType');
      //     this.getProductTypes(productTypeField);
      //   }
      // }, 1000);
      this.searchTimeout(this.getProductTypes, 'productType');
    },
    searchProductTag() {
      this.searchTimeout(this.loadProductTags, 'tags');
    },
    drawer() {
      console.log('drawer');
    },
    $route: function() {
      // if (this.$route.name.includes()) {
      //   a.status = true;
      // }
    },
    filterData: {
      handler: function() {
        let count = 0;
        for (let index = 0; index < this.filterData.length; index++) {
          let element = this.filterData[index];
          if (element.field == 'orderName') {
            // if (element.model[0] == '' || element.model[1] == '' || element.model.length == 0) {
            if (element.model0 != '' || element.model1 != '') {
              count = count + 1;
            }
          }
          if (element.scoped == 'order' && element.model == '') {
            count = count + 1;
          }
          if (element.scoped != 'order' && element.field != 'orderName') {
            if (!element.model || (element.model && element.model == '')) {
              count = count + 1;
            }
          }
        }
        if (count != 0 && count <= this.fields.length && this.filterData.length > 0) {
          this.offClear = false;
          //đk hiện curent select ở nút export
          this.$parent.$parent.$parent.checkCurentSearch = false;
        } else {
          this.offClear = true;
          this.$parent.$parent.$parent.checkCurentSearch = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    convertIdCollectionToTitle(item) {
      // this.listCollection là tất cả các collection, convert id thành title từ mảng collection này
      let result;
      if (item.field == 'collectionIds') {
        let res = this.listCollection.find(i => item.value == i._id);
        if (res) {
          result = res?.title;
          this.collectionSelected = result;
          // this.$emit('getCollectionName', this.collectionSelected);
        } else {
          result = '';
        }
      } else {
        result = item.value;
      }
      return result;
    },
    searchTimeout(getApi, filterType) {
      this.keyUpAt = new Date().getTime();
      setTimeout(() => {
        let now = new Date().getTime();
        if (this.keyUpAt && now > this.keyUpAt + 800) {
          this.keyUpAt = null;
          let productTypeField = this.fields.find(field => field.field == filterType);
          getApi(productTypeField);
        }
      }, 1000);
    },
    hideDrawer(e) {
      // console.log(e);
      // console.log('e.target.className', e.target.className, this.isTable);
      if (e.target.className === 'v-overlay__scrim' && this.isTable && this.isCheck) {
        this.$emit('update:drawer', true);
      }
    },
    onClickOutside() {
      return true;
    },
    checkDrawer(value) {
      this.isCheck = false;
      this.$emit('update:drawer', value);
      if (!value) this.isCheck = true;
    },
    clearClicked() {
      if (this.$route.query && this.$route.query._query) {
        let a = JSON.parse(this.$route.query._query);
        console.log(a);
        this.fields.forEach(f => {
          let b = a.find(q => f.field === q.field);
          console.log(b);
          if (b) {
            f.model = b.value;
          }
          this.$forceUpdate();
        });
        console.log(this.fields);
      }
    },
    getFulfillDate(data) {
      let vm = this;
      let newVal = {
        field: 'fulfillmentDate',
        value: data,
      };
      let findIndex = vm.filterData.findIndex(f => f.field === newVal.field);
      if (findIndex !== -1) {
        vm.$set(vm.filterData, findIndex, newVal);
      } else {
        vm.filterData.push(newVal);
      }
      // console.log(vm.filterData);
      this.fields.forEach(i => {
        if (i.field == 'fulfillmentDate') {
          i.model = data;
        }
      });
      this.callback(this.filterData);
    },
    getOrderDate(data) {
      console.log('data', data);
      let vm = this;
      let newVal = {
        field: 'orderDate',
        value: data,
      };
      let findIndex = vm.filterData.findIndex(f => f.field === newVal.field);
      if (findIndex !== -1) {
        vm.$set(vm.filterData, findIndex, newVal);
      } else {
        vm.filterData.push(newVal);
      }
      this.fields.forEach(i => {
        if (i.field == 'orderDate') {
          i.model = data;
          console.log(i.model);
        }
      });
      this.callback(this.filterData);
    },
    async loadItems(field) {
      if (field.resourceType === 'collections') {
        this.loadCollections(field);
      } else if (field.resourceType === 'tags') {
        this.loadProductTags(field);
      } else if (field.resourceType === 'productType') {
        this.getProductTypes(field);
      } else if (field.resourceType === 'vendor') {
        this.getProductVendors(field);
      }
    },
    async getCountry() {
      this.isLoading = true;
      try {
        let params = {
          limit: 100,
        };
        let data = await orderApi.getCountry(params);
        this.country = data.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    // get data ở filter product
    async loadProductTags(field) {
      this.isLoading = true;
      try {
        let params = {
          search: this.searchProductTag,
          limit: 1000,
        };
        let data = await productApi.getProductTags(params);
        field.items = data.data.tags;
        // console.log(data);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async getProductTypes(field) {
      this.isLoading = true;
      try {
        let params = {
          limit: 1000,
          search: this.searchProductType,
        };
        let data = await productApi.getProductTypes(params);
        // let data = await orderApi.get(params);
        console.log('getProductTypes', data);
        field.items = data.data.types;
        field.items.splice(0, 0, 'none');
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async getProductVendors(field) {
      this.isLoading = true;
      try {
        let params = {
          limit: 10000,
        };
        let data = await productApi.getProductVendors(params);
        field.items = data.data.vendors;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async loadCollections(field) {
      this.isLoading = true;
      try {
        let params = {
          limit: 10000,
        };
        let data = await collectionApi.get(params);
        this.listCollection = data.data.collections;
        field.items = data.data.collections;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    removeFilterData(field, i) {
      // filterData là cái query url
      let filter = this.filterData.find(f => f.field === field.field);
      let index = this.filterData.findIndex(f => f.field === field.field);
      console.log(filter.value);
      if (field.type.name == 'checkbox') {
        console.log(i);
        filter.value.splice(i, 1);
        if (!filter.value || filter.value.length == 0) {
          this.filterData.splice(index, 1);
        }
      } else {
        field.model = '';
        if (field.type.name == 'compare') {
          field.compare = '';
          field.model = '';
        }
        this.filterData.splice(index, 1);
      }
    },
    addCondition(i) {
      // console.log(this.filterData);
      // console.log(this.$route);
      var vm = this;
      let findIndex = this.filterData.findIndex(f => f.field == i.field);
      if (findIndex != -1) {
        let newVal = {
          field: i.field,
          compare: i.compare,
          fieldCompare: i.fieldCompare,
          value: i.model,
        };
        if (i.type.name === 'country') {
          let c = this.country.find(item => item.code === i.model);
          newVal.nameDislay = c.name;
        }
        if (i.type.name === 'order-name') {
          let a = [];
          a.push(i.model0);
          a.push(i.model1);
          // newVal.value = i.model.map(x => x);
          newVal.value = a.map(x => x);
        }
        vm.$set(vm.filterData, findIndex, newVal);
        // console.log(this.$route);
        console.log(1);
      } else {
        let newVal = {
          field: i.field,
          compare: i.compare,
          fieldCompare: i.fieldCompare,
          value: i.model,
        };
        // this.filterData.push();
        if (i.type.name === 'country') {
          let c = this.country.find(item => item.code === i.model);
          newVal.nameDislay = c.name;
        }
        if (i.type.name === 'order-name') {
          let a = [];
          a.push(i.model0);
          a.push(i.model1);
          // newVal.value = i.model;
          newVal.value = a;
          // newVal.value = `from${i.model.from}to${i.model.to}`;
        }
        if (newVal.value.length > 0 || newVal.value === true || newVal.value === false) {
          this.filterData.push(newVal);
        }
        console.log(2);
        console.log(newVal);
        // console.log(this.$route);
      }
      this.callback(this.filterData);
    },
    clear(field) {
      field.model = '';
      let index = this.filterData.findIndex(f => f.field === field.field);
      this.filterData.splice(index, 1);
      // field.model = null;
      // let filter = this.filterData.find(f => f.field == field.field);
      // filter.value = [];
    },
    clearFilter() {
      this.originalDate = {
        startDate: null,
        endDate: null,
      };
      this.filterData = [];
      this.fields.forEach(field => {
        // màn Order: cái order-number
        if (field.type.name == 'order-name') {
          field.model0 = '';
          field.model1 = '';
          // field.model = [];
        }
        // màn Order: những cái còn lại
        if (field.scoped == 'order') {
          field.model = '';
        }
        // màn Product:
        if (field.type.name == 'radio') {
          field.model = '';
        }
        // màn Product: cái có model ban đầu là null
        if (field.nullType) {
          field.model = null;
        }
        // màn Product: cái có model ban đầu là ''
        if (field.type.name == 'compare') {
          field.model = '';
        }
      });
      // this.offClear = true;
      this.callback([]);
      // this.callbackDone();
      this.$forceUpdate();
    },
    doneSelectFilter() {
      if (this.filterData) {
        this.filterData.map(item => {
          if (item.field == 'productType') {
            if (item.value == 'none') {
              item.value = '';
            }
          }
          return item;
        });
      }
      this.callbackDone(this.filterData);
      this.$parent.drawer = false;
      console.log('callbackDone in Filter Component');
      // this.$emit('getCollectionName', this.collectionSelected);
      this.$parent.getCollectionName();
      // console.log(this.collectionSelected);
    },
  },
};
</script>

<style lang="scss">
// .order-date {
//   position: relative !important;
// }
// .order-date-calender {
//   position: absolute !important;
// }
.setWidth {
  width: 350px;
}
.v-overlay--active {
  .v-overlay__scrim {
    opacity: 0.3 !important;
    border-color: none !important;
  }
}
.filter-component {
  .v-chip {
    white-space: normal;
  }
  .v-size--default {
    height: auto;
  }
  .v-chip__content {
    padding: 5px;
  }
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    //   position: relative;
    .label-filter {
      position: fixed;
      top: 0;
      z-index: 2;
      width: 100%;
      overflow: hidden;
      height: $height-header-main + 5;
      border-bottom: 1px solid $border-color;
      background: white;
    }
    .filter-list {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 65px;
      // max-height: calc(100% - 150px);
    }
    .container-filter {
      height: calc(100vh - 145px);
      overflow-y: auto;
    }
    .filter-footer {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 10;
    }
  }
}
// filterData là url query dạng array
</style>
