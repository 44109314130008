export default [
  {
    path: 'abandoned',
    name: 'website.abandoned',
    component: () => import('@/views/website/abandoned/AbandonedIndex'),
  },
  {
    path: 'abandoned/checkout/:id',
    name: 'website.abandoned.checkout',
    component: () => import('@/views/website/abandoned/AbandonedCheckout'),
  },
];
