<template>
  <v-pagination
    right
    :value="page"
    @input="changePage"
    :length="length"
    prev-icon="mdi-menu-left"
    next-icon="mdi-menu-right"
    :total-visible="visible"
  ></v-pagination>
</template>

<script>
export default {
  props: {
    visible: {
      type: Number,
      default: 7,
    },
    length: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    /**
     * Sync to http query
     */
    syncQuery: {
      type: String,
      default: 'page',
    },
  },
  methods: {
    changePage(value) {
      this.$emit('update:page', value);
    },
    // backToPage1() {
    //   console.log(2);
    //   this.$emit('update:page', 1);
    // },
    syncToRouteQuery() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query[this.syncQuery] = this.page;
      // Sync
      this.$router.replace({
        query,
      });
    },
  },
  watch: {
    page() {
      this.syncToRouteQuery();
    },
  },
};
</script>

<style lang="scss" scoped></style>
