import axios from 'axios';
import router from '../router';
import STORAGE_NAME from '@/const/storage';
import { HOST } from './hostConfig';
import event from '@/plugins/event-bus';

export default class Request {
  api = null;
  // store1212.lattehub.xyz
  constructor() {
    // eslint-disable-next-line no-undef
    this.api = axios.create({
      // eslint-disable-next-line no-undef
      baseURL: HOST.ACCOUNT_DOMAIN,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    this.api.interceptors.response.use(this.handleSuccess, this.handleError);
  }
  setBaseURL(baseURL) {
    this.api.defaults.baseURL = baseURL;
  }
  setToken(token) {
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.api.defaults.headers.common['x-access-token'] = token;
  }
  setRenewToken(token) {
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.api.defaults.headers.common['x-refresh-token'] = token;
  }
  setStoreId(storeId) {
    this.api.defaults.headers.common['x-store-id'] = storeId;
  }
  setURL(url) {
    this.api.defaults.baseURL = `${url}`;
  }
  handleSuccess(response) {
    // router.push({ name: 'page.404' });
    if (response && response.status === 401) {
      localStorage.removeItem(STORAGE_NAME.ACCESS_TOKEN);
      // location.reload();
      return Promise.reject(['Unauthorized']);
    }
    if (response && response.status === 404) {
      return Promise.reject(['404 not found']);
    } else {
      if (response && (response.status === 200 || response.status === 201)) {
        if (
          response.config.method !== 'get' &&
          response.config.url !== '/product-mass-updates/preview' &&
          !response.config.url.includes(`/reviews/aliProducts/`) &&
          !response.config.url.includes(`products/options/template/`) &&
          !response.config.url.includes(`stores/config/skip`) &&
          !response.config.url.includes(`create-store`) &&
          !response.config.url.includes(`users/stores`)
          ///stores/config/skip
        ) {
          event.$emit('success-api');
        }
        if (response.config.method === 'put') {
          // router.push({ name: 'page.404' });
        }
        return Promise.resolve(response);
      } else {
        event.$emit('error-api', response.data.message);
        Promise.reject(response);
      }
      // if()
    }
  }

  handleError(error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem(STORAGE_NAME.ACCESS_TOKEN);
      if (
        error.response.config.url === '/auth/account-activation' ||
        error.response.config.url === '/auth/accept' ||
        error.response.config.url?.trim() === '/auth/check-staff-available'
      ) {
        return Promise.reject('401');
      } else {
        location.reload();
        Promise.reject('401');
      }
    }
    if (error.response && error.response.status === 404) {
      return Promise.reject(['404 not found']);
    }
    if (error && error.response && error.response.status === 500) {
      // localStorage.removeItem(STORAGE_NAME.ACCESS_TOKEN);
      Promise.reject('500');
      // location.reload();
    }
    if (error && error.response && error.response.status === 504) {
      event.$emit('error-api', ['Request Time out']);
      return Promise.reject('Request Time out');
    }
    if (error && error.response && error.response.status === 502) {
      event.$emit('error-api', ['Bad Gateway']);
      return Promise.reject('Bad Gateway');
    } else {
      const checkMessageErros = error => {
        let message = [];
        if (
          error.response.data &&
          error.response.data.constraints &&
          typeof error.response.data.constraints[0] === 'string'
        ) {
          message = error.response.data.constraints;
        } else {
          message = Array.isArray(error.response.data.message)
            ? error.response.data.message
            : [error.response.data.message || 'Oops! Unknown error!'];
        }
        return message;
      };

      if (error.response && error.response.data) {
        let errorMessage = checkMessageErros(error);
        event.$emit('error-api', errorMessage);
        return Promise.reject(errorMessage || 'Oops! Unknown error!');
      } else return Promise.reject(['Oops! Unknown error!']);
    }
  }

  get(path, options) {
    return this.api.get(path, options);
  }
  patch(path, payload) {
    return this.api.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    });
  }
  put(path, payload) {
    return this.api.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
    });
  }
  delete(path, payload) {
    return this.api.request({
      method: 'DELETE',
      url: path,
      data: payload,
      responseType: 'json',
    });
  }
  post(path, payload) {
    return this.api.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    });
  }
}
