const state = {
  model: null,
};

const mutations = {
  'character/load': (state, model) => {
    state.model = model;
  },
  'character/release': state => {
    state.model = null;
  },
};

export default {
  state,
  mutations,
};
