<template>
  <v-dialog persistent v-model="$parent.showDialogDelete" width="700" style="transform-origin: center top">
    <v-card relative>
      <!-- Title -->
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <!-- Close icon -->
      <div class="close-icon" @click="$parent.showDialogDelete = false">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class="mb-3"></v-divider>
      <!-- Content -->
      <v-card-text class="">
        Are you sure you want to delete
        <span class="name-delete">{{ ' ' + content + ' ' }}</span>
        . This action cannot be reversed.
      </v-card-text>

      <v-divider></v-divider>

      <!-- Footer -->
      <v-card-actions v-if="showFooter">
        <v-spacer></v-spacer>
        <v-btn color="" @click="$parent.showDialogDelete = false"> Close </v-btn>
        <v-btn color="error" @click="$parent.submitDelete"> Delete {{ titleButton }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showFooter: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleButton: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.name-delete {
  font-size: 18px;
  font-weight: bold;
}
</style>
