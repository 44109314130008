import analyticRequest from './request/analyticRequest';
import storeRequest from './request/storeRequest';

import STORAGE_NAME from '@/const/storage';

const fetchConversionRate = (fromTime, toTime, stores, tz) => {
  updateBaseURL();
  return analyticRequest.get(`/actions/statistic`, {
    params: {
      from_time: fromTime,
      to_time: toTime,
      stores: stores,
      timezone: tz,
    },
  });
};

const fetchTotalSales = (fromTime, toTime, stores, tz) => {
  updateBaseURL();
  return analyticRequest.get(`/orders/total-sales`, {
    params: {
      from_time: fromTime,
      to_time: toTime,
      stores: Array.isArray(stores) ? stores.join(',') : stores,
      timezone: tz,
    },
  });
};

const fetchTotalOrders = (fromTime, toTime, stores, tz) => {
  updateBaseURL();
  return analyticRequest.get(`/orders/total-orders`, {
    params: {
      from_time: fromTime,
      to_time: toTime,
      stores: Array.isArray(stores) ? stores.join(',') : stores,
      timezone: tz,
    },
  });
};

const fetchAverageOdersValue = (fromTime, toTime, stores, tz) => {
  updateBaseURL();
  return analyticRequest.get(`/orders/aov`, {
    params: {
      from_time: fromTime,
      to_time: toTime,
      stores: Array.isArray(stores) ? stores.join(',') : stores,
      timezone: tz,
    },
  });
};

const fetchAverageOderItems = (fromTime, toTime, stores, tz) => {
  updateBaseURL();
  return analyticRequest.get(`/orders/aoi`, {
    params: {
      from_time: fromTime,
      to_time: toTime,
      stores: Array.isArray(stores) ? stores.join(',') : stores,
      timezone: tz,
    },
  });
};

const fetchProductSalesReport = params => {
  updateBaseURL();
  return analyticRequest.get(`/products/performance`, {
    params: params,
  });
};

const fetchTrafficSource = (fromTime, toTime, stores, tz) => {
  updateBaseURL();
  return analyticRequest.get(`/actions/traffic-source`, {
    params: {
      from_time: fromTime,
      to_time: toTime,
      stores: Array.isArray(stores) ? stores.join(',') : stores,
      timezone: tz,
    },
  });
};

const fetchTrafficLocation = (fromTime, toTime, stores, tz) => {
  updateBaseURL();
  return analyticRequest.get(`/actions/traffic-location`, {
    params: {
      from_time: fromTime,
      to_time: toTime,
      stores: Array.isArray(stores) ? stores.join(',') : stores,
      timezone: tz,
    },
  });
};

const updateBaseURL = () => {
  let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
  analyticRequest.setBaseURL('https://' + storeDomain + '/api/report');
  let token = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
  if (token) {
    analyticRequest.setToken(token);
  }
};

const fetchAbcheckoutConversion = filters => {
  updateBaseURL();
  return storeRequest.get(`/orders/abcheckouts/statistics/counter`, filters);
};

export const AnalyticService = {
  fetchConversionRate,
  fetchTotalSales,
  fetchTotalOrders,
  fetchAverageOdersValue,
  fetchAverageOderItems,
  fetchProductSalesReport,
  fetchTrafficSource,
  fetchTrafficLocation,
  fetchAbcheckoutConversion,
};
