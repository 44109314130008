import { AnalyticService } from '../../apis/analytics';
import { FETCH_CONVERSION_RATE_END } from '../mutations.type';

import { FETCH_CONVERSION_RATE } from '../actions.type';
const initialState = {
  chartData: {
    labels: ['VIEW PRODUCT', 'ADDED TO CART', 'REACHED CHECKOUT', 'SESSION CONVERTED'],
    datasets: [
      {
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        pointBorderColor: 'rgba(255, 206, 86, 0.2)',
        data: [0, 0, 0, 0], //[2, 2, 2, 2],
        barThickness: 25,
      },
    ],
  },
  chartOptions: {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 100,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          ticks: {
            callback: function(label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(' ');
              } else {
                return label;
              }
            },
            maxRotation: 0,
            fontSize: 10,
            padding: 10,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        display: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1,
      onComplete: function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
    title: {
      display: true,
      fontSize: 12,
    },
  },
  conversionRate: 0,
  uselConversionRate: 0,
  onlineStoreConversionRate: 0,
  viewProductNum: 0,
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_CONVERSION_RATE](context, args) {
    // avoid extronuous network call if article exists
    // if (fromeTime == undefined || toTime == undefined) {
    //   return context.commit(FETCH_CONVERSION_RATE_END, prevArticle);
    // }
    let strStores = Array.isArray(args.stores) ? args.stores.join(',') : args.stores;
    const { data } = await AnalyticService.fetchConversionRate(args.fromTime, args.toTime, strStores, args?.timezone);
    let chartData = {
      labels: ['PRODUCT VISITOR', 'ADDED TO CART', 'REACHED CHECKOUT', 'SESSION CONVERTED'],
      datasets: [
        {
          backgroundColor: 'rgb(255, 180, 14)',
          pointBorderColor: 'rgb(255, 180, 14)',
          data: [
            //  data['view_content']['is_tracked_previous_action'],
            data['vistors'] || 0,
            data['add_to_cart']['is_tracked_previous_action'],
            data['reached_checkout']['is_tracked_previous_action'],
            data['session_converted']['is_tracked_previous_action'],
          ],
          barThickness: 25,
        },
      ],
    };

    let totalViewContent = data['vistors'] + data['view_content']['is_not_tracked_previous_action'] || 1;
    let totalSessionConverted =
      data['session_converted']['is_tracked_previous_action'] +
      data['session_converted']['is_not_tracked_previous_action'];

    let conversionRate = totalSessionConverted > 0 ? ((totalSessionConverted / totalViewContent) * 100).toFixed(2) : 0;
    let uselConversionRate = data?.upsell > 0 ? ((data?.upsell / totalViewContent) * 100).toFixed(2) : 0;
    let onlineStoreConversionRate =
      data?.onlineStore > 0 ? ((data?.onlineStore / totalViewContent) * 100).toFixed(2) : 0;
    let viewProductNum = data['view_content']['is_tracked_previous_action'] || 0;
    let rs = { chartData, conversionRate, uselConversionRate, onlineStoreConversionRate, viewProductNum };
    context.commit(FETCH_CONVERSION_RATE_END, rs);
    return rs;
  },
};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  [FETCH_CONVERSION_RATE_END](state, data) {
    state.chartData = data.chartData;
    state.conversionRate = data.conversionRate;
    state.uselConversionRate = data.uselConversionRate;
    state.onlineStoreConversionRate = data.onlineStoreConversionRate;
    state.viewProductNum = data.viewProductNum;
  },
};

const getters = {
  chartData() {
    return state.chartData;
  },
  chartOptions() {
    return state.chartOptions;
  },
  conversionRate() {
    return state.conversionRate;
  },
  uselConversionRate() {
    return state.uselConversionRate;
  },
  onlineStoreConversionRate() {
    return state.onlineStoreConversionRate;
  },
  viewProductNum() {
    return state.viewProductNum;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
