import { AnalyticService } from '../../apis/analytics';
import { FETCH_AOV_RATE_DONE } from '../mutations.type';
import { FETCH_AOV_RATE } from '../actions.type';

const initialState = {
  chartData: {
    datasets: [
      {
        data: [],
      },
    ],
  },
  options: {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 50,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            displayFormats: {
              hour: 'hA',
            },
          },
          ticks: {
            maxTicksLimit: 4,
            maxRotation: 0,
            padding: 10,
            fontSize: 10,
          },
          gridLines: { display: false },
        },
      ],
    },
    legend: {
      display: false,
    },
    responsive: true,
  },
  aov: 0,
  uselAov: 0,
  onlineStoreAov: 0,
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_AOV_RATE](context, args) {
    let strStores = Array.isArray(args.stores) ? args?.stores.join(',') : args.stores;
    const { data } = await AnalyticService.fetchAverageOdersValue(
      args.fromTime,
      args.toTime,
      strStores,
      args?.timezone,
    );
    let dataset = {
      data: data.details.dataset,
      borderColor: 'rgb(63, 188, 26)',
      backgroundColor: 'rgb(63, 188, 26)',
      fill: false,
      pointRadius: 1,
      pointBackgroundColor: 'rgba(0, 0, 0, 0.1)',
      pointHitRadius: 5,
    };

    let maxValue = Math.max(...data.details.dataset);
    let stepSize = maxValue === 0 ? 20 : maxValue > 20 ? Math.round(maxValue / 4) : maxValue;
    initialState.options.scales.yAxes[0].ticks.stepSize = stepSize;
    let rs = {
      chartData: { datasets: [dataset], labels: data.details.labels },
      options: { ...initialState.options },
      aov: Math.round(data?.aov * 100) / 100 || 0,
      uselAov: Math.round(data?.upsell_aov * 100) / 100 || 0,
      onlineStoreAov: Math.round(data?.online_store_aov * 100) / 100 || 0,
    };
    context.commit(FETCH_AOV_RATE_DONE, rs);
    return rs;
  },
};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  [FETCH_AOV_RATE_DONE](state, data) {
    state.chartData = data.chartData;
    state.options = data.options;
    state.aov = data.aov;
    state.uselAov = data.uselAov;
    state.onlineStoreAov = data.onlineStoreAov;
  },
};

const getters = {
  chartAovData(state) {
    return state.chartData;
  },
  chartAovOpts(state) {
    return state.options;
  },
  aov(state) {
    return state.aov;
  },
  uselAov(state) {
    return state.uselAov;
  },
  onlineStoreAov(state) {
    return state.onlineStoreAov;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
