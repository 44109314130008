export default {
  validate: {
    required: '{field} required',
    domain: 'Domain is url link',
    alpha: 'Alpha is letter English',
    minLength: 'Min length is {number}',
    maxLength: 'Max length is {number}',
    email: 'This is required email',
  },
};
