export default [
  {
    path: 'customer',
    name: 'website.customer',
    component: () => import('@/views/website/customer'),
  },

  {
    path: 'customer/create',
    name: 'website.customer.create',
    component: () => import('@/views/website/customer/Create'),
  },

  {
    path: 'customer/update/:id',
    name: 'website.customer.update',
    props: true,
    component: () => import('@/views/website/customer/Update'),
  },
];
