<template>
  <transition name="fade">
    <v-col class="d-flex align-center group-button-submit__create">
      <span class="image">
        <!-- <img width="100px" :src="imageFake.Logo" /> -->
      </span>
      <h3>Unsaved changes</h3>
      <v-spacer></v-spacer>
      <!-- <v-btn color="error" class="mr-3" @click="callbackCancel">Discard</v-btn> -->
      <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="callbackSubmit">{{
        mode === 'create' ? 'Create' : 'Save'
      }}</v-btn>
    </v-col>
  </transition>
</template>

<script>
import { imageFake } from '@/const/image';

export default {
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    callbackCancel: {
      type: Function,
      default: () => {},
    },
    callbackSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      imageFake,
    };
  },
};
</script>

<style lang="scss" scoped>
.group-button-submit__create {
  position: fixed;
  background-color: #303030;
  width: 5000px;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 10000;
  // width: 100%;
  top: 0;
  color: white;
  height: 66px;
  right: -30px;
  left: -0px;
  // right: 20%;
  .image {
    width: 258px;
    img {
      width: 120px;
    }
  }
}
</style>
